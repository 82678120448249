import React, { useState } from 'react';
import styles from './styles.module.scss';
import { API_BASE_URL } from '../../../../constants';
import { axiosAuthFetch } from '../../../../helpers/axios';
import Image from 'react-bootstrap/Image';
import { getLocalStorageItem } from '../../../global/storage';

const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <span key={i} className={i <= rating ? styles.starFilled : styles.starEmpty}>&#9733;</span>
        );
    }
    return stars;
};

export default function CheckInDetails({ selectedCheckIn, checkInId }) {
    
    const [coachComment, setCoachComment] = useState(selectedCheckIn?.coach_comment);
    const [checkIn, setCheckIn] = useState(selectedCheckIn);
    const [isSaving, setIsSaving] = useState(false);
    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo.user;
    //eslint-disable-next-line
    const [isCoach, setIsCoach] = useState(user.isCoach);

        console.log(isCoach);
    
        if (!selectedCheckIn) return null; // Sikkerhetsjekk

        const formatDate = (dateString) => {
            const options = { day: '2-digit', month: 'long' };
            return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateString));
        };

        // const handleSaveComment = async () => {
        //         const url = `${API_BASE_URL}/check-ins/${checkInId}`; // Use the id from the full check-in object
        //         await axiosAuthFetch(url, {
        //             data: {
        //                 coach_comment: coachComment,
        //             },
        //         }, 'PUT');
        //         alert('Operasjonen er vellykket!');
        //         navigate('')
        //     };

        

        const handleSaveComment = async () => {
            setIsSaving(true);
            try {
                const url = `${API_BASE_URL}/check-ins/${checkInId}`;
                const updatedCheckIn = await axiosAuthFetch(url, {
                    data: {
                        coach_comment: coachComment,
                    },
                }, 'PUT');
                console.log(updatedCheckIn.data);

                alert('Operasjonen er vellykket!');

                setCheckIn((prev) => ({
                    ...prev,
                    attributes: {
                        ...prev.attributes,
                        coach_comment: coachComment,
                    },
                }));
            } catch (error) {
                console.error("Feil ved lagring av kommentar", error);
                alert("Noe gikk galt, prøv igjen.");
            } finally {
                setIsSaving(false);
            }
        };

        const handleDeleteCheckIn = async () => {
            if (!window.confirm('Er du sikker på at du vil slette denne check-in?')) {
              return;
            }
            try {
              const url = `${API_BASE_URL}/check-ins/${checkInId}`;
              await axiosAuthFetch(url, {}, 'DELETE');
              alert('Check-in er slettet!');
              // Hvis du trenger å oppdatere en liste i parent, kan du kalle onDeleteSuccess eller lignende:
            } catch (error) {
              console.error('Feil ved sletting av check-in', error);
              alert('Kunne ikke slette check-in, prøv igjen.');
            }
          };

        return(
            <div className={`${styles.container} d-flex col-11 m-auto flex-column align-items-center`}>
                <div className={styles.modalChild1}>
                    <p><strong>Dato:</strong> {formatDate(selectedCheckIn.attributes.date)}</p>
                    <p><strong>Vekt:</strong> {selectedCheckIn.attributes.weight} kg</p>
                    <p><strong>Trening:</strong> {renderStars(selectedCheckIn.attributes.rating_training)}</p>
                    <p><strong>Motivasjon:</strong> {renderStars(selectedCheckIn.attributes.rating_motivation)}</p>
                    <p><strong>Kosthold:</strong> {renderStars(selectedCheckIn.attributes.rating_nutrition)}</p>
                    <p><strong>Søvn:</strong> {renderStars(selectedCheckIn.attributes.rating_sleep)}</p>
                    <p><strong>Klients kommentar:</strong> {selectedCheckIn.attributes.comment}</p>
                    <p><strong>Coach kommentar:</strong> {checkIn.attributes.coach_comment}</p>
                </div>
                <div className={styles.modalImageParent}>
                    {selectedCheckIn.attributes.progress_pic?.data?.length > 0 && (
                        <div className={styles.images}>
                            {selectedCheckIn.attributes.progress_pic.data.map((pic, index) => (
                                <Image
                                    key={index}
                                    src={pic.attributes.url}
                                    alt={`Progress ${index}`}
                                    fluid
                                    className={styles.largeImage}
                                />
                            ))}
                        </div>
                    )}
                </div>
                {isCoach ? (
                        <>
                        <textarea
                            className={`${styles.coachCommentTextarea} col-12 rounded-3 p-3`}
                            value={coachComment}
                            onChange={(e) => setCoachComment(e.target.value)}
                            rows="6"
                            placeholder="Skriv din kommentar her"
                        />
                        <div
                            className={`col-10 text-center rounded-5 my-3 bg-primary p-2 ${styles.submit}`}
                            onClick={!isSaving ? handleSaveComment : null}
                        >
                            {isSaving ? 'Lagrer...' : 'Lagre'}
                        </div>
                        </>
                    ) : (
                        // Hvis brukeren IKKE er coach, vis en slette-knapp
                        <div
                        className={`col-10 text-center rounded-5 my-3 bg-danger p-2 ${styles.submit}`}
                        onClick={handleDeleteCheckIn}
                        >
                        Slett Check-in
                        </div>
                    )}
            </div>
        )
}