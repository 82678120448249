import React from 'react';
import {ReactComponent as RightArrow} from '../../../images/icons/right-arrow.svg';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router';

export default function MealPlansList({ mealplan }) {
    //eslint-disable-next-line
    const { name , total_kcals, fat, protein, carbs} = mealplan.attributes;

    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/mealplan/${mealplan.id}`);
    }

    return(
            <div className={`${styles.card} col-11 p-3 d-flex align-items-center justify-content-between`} onClick={handleCardClick}>
                <div className={`col-10 ${styles.sub}`}>
                    <div className={`col-7 ${styles.name}`}>{name}</div>
                    <div className={` ${styles.kcals}`}>kcals: {total_kcals}</div>
                </div>
                {/* <div className={`col-5 ${styles.makro}`}>
                    <div className="">F: {fat}</div>
                    <div className="">P: {protein}</div>
                    <div className="">C: {carbs}</div>
                </div> */}
                <div className="col-1">
                    <RightArrow style={{ fill: 'white' }}/>
                </div>
            </div>
    )
}