import { axiosAuthFetch } from "../../../helpers/axios";
import { API_GRAPHQL } from "../../../constants";


export async function GetSingleSessionInfo(id) {

const query = `
    query {
        workoutSession(id:"${id}") {
            data{
                id
                attributes{
                    date_performed
                    name
                    clients_comments
                    exercise_logs(pagination: {limit: 100}) {
                        data{
                            id
                            attributes{
                                sett
                                reps
                                vekt
                                order
                                exercise{
                                    data{
                                        id
                                        attributes{
                                            name
                                            image{
                                                data{
                                                    id
                                                    attributes{
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    program{
                        data{
                            id
                            attributes{
                                programName
                                cover_image{
                                    data{
                                        id
                                        attributes{
                                            images{
                                                data{
                                                    id
                                                    attributes{
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    client_comment
                    rating
                    coach_comments
                    duration
                }
            }
        }
    }
`

const response = await axiosAuthFetch( API_GRAPHQL, { query }, 'post')
console.log('workoutRESPONSE', response.data);

return response.data.workoutSession.data.attributes;

}