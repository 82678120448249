import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import VariantCard from './variantCard';
import { axiosAuthFetch } from '../../../../../helpers/axios';
import { API_BASE_URL, API_GRAPHQL } from '../../../../../constants';
import Modal from 'react-bootstrap/Modal';

const computeMacros = (ingredients) => {
  let totalProtein = 0;
  let totalCarbs = 0;
  let totalFat = 0;
  let totalKcals = 0;

  ingredients.forEach((ingredient) => {
    if (!ingredient.macros) return;

    const { amount, macros, unit } = ingredient;

    if (unit === 'stk') {
      totalProtein += macros.protein * amount;
      totalCarbs += macros.carbs * amount;
      totalFat += macros.fat * amount;
      totalKcals += macros.kcals * amount;
    } else if (unit === 'g') {
      totalProtein += (macros.protein / 100) * amount;
      totalCarbs += (macros.carbs / 100) * amount;
      totalFat += (macros.fat / 100) * amount;
      totalKcals += (macros.kcals / 100) * amount;
    } else if (unit === 'dl') {
      // Forenklet antagelse: 1 dl = 100 g
      const gramsEquivalent = amount * 100;
      totalProtein += (macros.protein / 100) * gramsEquivalent;
      totalCarbs += (macros.carbs / 100) * gramsEquivalent;
      totalFat += (macros.fat / 100) * gramsEquivalent;
      totalKcals += (macros.kcals / 100) * gramsEquivalent;
    }
  });

  return {
    protein: Math.round(totalProtein),
    carbs: Math.round(totalCarbs),
    fat: Math.round(totalFat),
    kcals: Math.round(totalKcals)
  };
};

export default function VariantsList({ variants, allIngredients, mealId, mealData, clients }) {

    console.log(mealData);

    const initialCustomIngredients = (mealData?.attributes?.meal_ingredients.data || []).map(
        (ingredient) => ({
          id: null, // Use the ingredient's actual ID
          name: ingredient.attributes.ingredients.data[0]?.attributes.name, // Get the ingredient's name from nested data
          amount: ingredient.attributes.amount, // Amount of the ingredient
          unit: ingredient.attributes.unit, // Unit of the ingredient
          macros: ingredient.attributes.ingredients.data[0]?.attributes.macros[0] || {}, // Macros, assuming it's an array with at least one object
          ingredientId: ingredient.attributes.ingredients.data[0]?.id || null, // Ingredient ID
        })
      );

    const [showModal, setShowModal] = useState(false);
    const [customIngredients, setCustomIngredients] = useState(initialCustomIngredients);
    const [totalMacros, setTotalMacros] = useState({ protein: 0, carbs: 0, fat: 0, kcals: 0 });
    // const [variantClients, setVariantClients] = useState(mealData?.attributes?.clients?.data || []);
    const [ingredientSearch, setIngredientSearch] = useState('');
    const [filteredIngredients, setFilteredIngredients] = useState([]);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [newIngredientAmount, setNewIngredientAmount] = useState('');
    const [newIngredientUnit, setNewIngredientUnit] = useState('g');
    const [newVariantName, setNewVariantName] = useState('');

    console.log(customIngredients);
    // Funksjon for å oppdatere en variant
    const updateVariant = async ({ variantId, variantName, ingredients }) => {
        try {

            const computedMacros = computeMacros(ingredients);
            const variantData = {
                variant_name: variantName,
                custom_ingredients: ingredients.map(ingredient => ({
                    amount: ingredient.amount,
                    unit: ingredient.unit,
                    ingredients: ingredient.ingredientId ? [ingredient.ingredientId] : [],
                    ingredient_name: ingredient.name,
                    macros: ingredient.macros,
                })),
                proteins: computedMacros.protein,
                carbs: computedMacros.carbs,
                fats: computedMacros.fat,
                kcals: computedMacros.kcals,
            };

            await axiosAuthFetch(`${API_BASE_URL}/meal-instances/${variantId}`, { data: variantData }, 'PUT');
            alert('Varianten er oppdatert!');
            window.location.reload();
        } catch (error) {
            console.error('Error updating variant:', error);
            alert('Det oppstod en feil ved oppdatering av varianten.');
        }
    };

    useEffect(() => {
        if(mealData?.attributes?.meal_ingredients?.data) {
            const ingredients = mealData?.attributes?.meal_ingredients.data.map(
                (ingredient) => ({
                  id: null, // Use the ingredient's actual ID
                  name: ingredient.attributes.ingredients.data[0]?.attributes.name, // Get the ingredient's name from nested data
                  amount: ingredient.attributes.amount, // Amount of the ingredient
                  unit: ingredient.attributes.unit, // Unit of the ingredient
                  macros: ingredient.attributes.ingredients.data[0]?.attributes.macros[0] || {}, // Macros, assuming it's an array with at least one object
                  ingredientId: ingredient.attributes.ingredients.data[0]?.id || null, // Ingredient ID
                })
            );
            setCustomIngredients(ingredients)
        }
    }, [mealData]);
    console.log(customIngredients);

    const createVariant = async ({ variantName, ingredients }) => {
        try {

            const computedMacros = computeMacros(ingredients);

            const variantData = {
                variant_name: variantName,
                meal: mealId,
                custom_ingredients: ingredients.map(ingredient => ({
                    amount: ingredient.amount,
                    unit: ingredient.unit,
                    ingredients: ingredient.ingredientId ? [ingredient.ingredientId] : [],
                    ingredient_name: ingredient.name,
                    macros: ingredient.macros,
                })),
                proteins: computedMacros.protein,
                carbs: computedMacros.carbs,
                fats: computedMacros.fat,
                kcals: computedMacros.kcals,
            };

            await axiosAuthFetch(`${API_BASE_URL}/meal-instances`, { data: variantData }, 'POST');
            alert('Ny variant opprettet!');
            window.location.reload();
        } catch (error) {
            console.error('Error creating variant:', error);
            alert('Det oppstod en feil ved oppretting av varianten.');
        }
    };

    // Funksjon for å slette en variant
    const deleteVariant = async (variantId) => {
        try {
            await axiosAuthFetch(`${API_BASE_URL}/meal-instances/${variantId}`, {}, 'DELETE');
            alert('Varianten er slettet!');
            window.location.reload();
        } catch (error) {
            console.error('Error deleting variant:', error);
            alert('Det oppstod en feil ved sletting av varianten.');
        }
    };

    // Funksjon for å legge til en klient i en variant
    const addClientToVariant = async ({ variantId, clientId }) => {
        try {

            const query = `query {
                mealInstances(filters: { meal: { id: { eq: ${mealId} } }, clients: { id: { eq: ${clientId} } } }) {
                    data {
                        id
                        attributes {
                            clients {
                                data {
                                    id
                                }
                            }
                        }
                    }
                }
                meals(filters: {id: {eq:${mealId}}, clients: {id: {eq:${clientId}}}}) {
                    data{
                    id
                    attributes{
                        clients{
                        data{
                            id
                        }
                        }
                    }
                    }
                }
            }`;
          // Hent alle meal instances (varianter) for dette måltidet som klienten er tilknyttet
          const response = await axiosAuthFetch(`${API_GRAPHQL}`, {query}, 'POST');
          console.log(response);
          const existingInstances = response.data.mealInstances.data.map(instance => ({
            id: instance.id,
            clientIds: instance.attributes.clients.data.map(client => client.id),
          }));

          const isClientInMainMeal = response.data.meals.data.length > 0;
      
          console.log(existingInstances, isClientInMainMeal);
      
          for (const instance of existingInstances) {
            if (instance.clientIds.includes(clientId)) {
              await axiosAuthFetch(`${API_BASE_URL}/meal-instances/${instance.id}`, {
                data: {clients: {disconnect: [clientId]}}
              }, 'PUT');
            }
          }
          
          // Disconnect client from main meal if they are associated
          if (isClientInMainMeal) {
            await axiosAuthFetch(`${API_BASE_URL}/meals/${mealId}`, {
                data: {clients: {disconnect: [clientId]}}
              }, 'PUT');
          }
      
          // Legg klienten til den nye varianten
          await axiosAuthFetch(`${API_BASE_URL}/meal-instances/${variantId}`, {
            data: { clients: { connect: [clientId] } },
          }, 'PUT');
      
          alert('Klient lagt til i varianten!');
        } catch (error) {
          console.error('Error adding client to variant:', error);
          alert('Det oppstod en feil ved å legge til klienten.');
        }
      };



  const handleIngredientChange = (index, newAmount) => {
    const updatedIngredients = [...customIngredients];
    updatedIngredients[index] = {
      ...updatedIngredients[index],
      amount: parseFloat(newAmount) || '',
    };
    setCustomIngredients(updatedIngredients);
  };

  const calculateMacros = () => {
    let totalProtein = 0;
    let totalCarbs = 0;
    let totalFat = 0;
    let totalKcals = 0;

    customIngredients.forEach((ingredient) => {
      const { amount, macros, unit } = ingredient;

      if (!macros) return;

      if (unit === 'stk') {
        totalProtein += macros.protein * amount;
        totalCarbs += macros.carbs * amount;
        totalFat += macros.fat * amount;
        totalKcals += macros.kcals * amount;
      } else if (unit === 'g') {
        totalProtein += (macros.protein / 100) * amount;
        totalCarbs += (macros.carbs / 100) * amount;
        totalFat += (macros.fat / 100) * amount;
        totalKcals += (macros.kcals / 100) * amount;
      } else if (unit === 'dl') {
        const gramsEquivalent = amount * 100;
        totalProtein += (macros.protein / 100) * gramsEquivalent;
        totalCarbs += (macros.carbs / 100) * gramsEquivalent;
        totalFat += (macros.fat / 100) * gramsEquivalent;
        totalKcals += (macros.kcals / 100) * gramsEquivalent;
      }
    });

    setTotalMacros({
      protein: Math.round(totalProtein),
      carbs: Math.round(totalCarbs),
      fat: Math.round(totalFat),
      kcals: Math.round(totalKcals),
    });
  };

  useEffect(() => {
    calculateMacros();
    //eslint-disable-next-line
  }, [customIngredients]);

  const handleIngredientSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setIngredientSearch(searchValue);
    setFilteredIngredients(
      searchValue
        ? allIngredients.filter((ingredient) =>
            ingredient.attributes.name.toLowerCase().includes(searchValue)
          )
        : []
    );
  };

  const handleAddIngredient = () => {
    if (!selectedIngredient || !newIngredientAmount) return;

    const newIngredient = {
      id: null,
      name: selectedIngredient.attributes.name,
      amount: parseFloat(newIngredientAmount),
      unit: newIngredientUnit,
      macros: selectedIngredient.attributes.macros[0],
      ingredientId: selectedIngredient.id,
    };

    setCustomIngredients([...customIngredients, newIngredient]);
    setIngredientSearch('');
    setSelectedIngredient(null);
    setNewIngredientAmount('');
    setNewIngredientUnit('g');
  };

  const handleRemoveIngredient = (index) => {
    const updatedIngredients = customIngredients.filter((_, i) => i !== index);
    setCustomIngredients(updatedIngredients);
  };

  const handleVariantNameInput = (e) => {
    setNewVariantName(e.target.value);
  };

    // Funksjon for å fjerne en klient fra en variant
    const removeClientFromVariant = async ({ variantId, clientId }) => {
        try {
          // Fjern klienten fra varianten
          await axiosAuthFetch(`${API_BASE_URL}/meal-instances/${variantId}`, {
            data: { clients: { disconnect: [clientId] } },
          }, 'PUT');
      
          // Valgfritt: Legg klienten tilbake til hovedmåltidet
          await axiosAuthFetch(`${API_BASE_URL}/meals/${mealId}`, {
            data: { clients: { connect: [clientId] } },
          }, 'PUT');
      
          alert('Klient fjernet fra varianten!');
        } catch (error) {
          console.error('Error removing client from variant:', error);
          alert('Det oppstod en feil ved å fjerne klienten.');
        }
      };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.overskrift}><h3>Varianter av måltidet</h3></div>
                <div className={styles.addButton} onClick={() => setShowModal(true)}>Opprett ny</div>
            </div>
            <div className={styles.listContainer}>
                {variants.map((variant, index) => (
                    <VariantCard 
                        key={variant.id}
                        index={index}
                        variant={variant}
                        clients={clients}
                        allIngredients={allIngredients}
                        updateVariant={updateVariant}
                        deleteVariant={deleteVariant}
                        addClientToVariant={addClientToVariant}
                        removeClientFromVariant={removeClientFromVariant}
                    />
                ))}
            </div>
            <Modal className={styles.modal} show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Legg til en ny variant</Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.modalContainer}>
                 <h3>Ingredienser</h3>
                    {customIngredients.map((ingredient, index) => (
                        <div key={index} className={styles.ingredientAdjustContainer}>
                        <label className="col-5">{ingredient.name}</label>
                        <input
                            type="number"
                            value={ingredient.amount}
                            onChange={(e) => handleIngredientChange(index, e.target.value)}
                            className={styles.ingredientInput}
                        />
                        <span>{ingredient.unit}</span>
                        <button
                            onClick={() => handleRemoveIngredient(index)}
                            className={styles.removeIngredientButton}
                        >
                            Fjern
                        </button>
                        </div>
                    ))}

          {/* Legg til ny ingrediens */}
          {/* <div className={styles.addIngredientContainer}>
            <h4 className="my-4">Legg til ingrediens</h4>
            <input
              type="text"
              value={ingredientSearch}
              onChange={handleIngredientSearch}
              placeholder="Søk etter ingrediens"
              className={styles.ingredientSearchInput}
            />
            <div className={styles.filteredIngredients}>
              {filteredIngredients.map((ingredient) => (
                <div
                  key={ingredient.id}
                  className={`${styles.ingredientOption} ${
                    selectedIngredient && selectedIngredient.id === ingredient.id
                      ? styles.selected
                      : ''
                  }`}
                  onClick={() => setSelectedIngredient(ingredient)}
                >
                  {ingredient.attributes.name}
                </div>
              ))}
            </div>
            {selectedIngredient && (
              <div className={styles.addIngredientDetails}>
                <input
                  type="number"
                  value={newIngredientAmount}
                  onChange={(e) => setNewIngredientAmount(e.target.value)}
                  placeholder="Mengde"
                  className={styles.ingredientAmountInput}
                />
                {newIngredientUnit}
                <button onClick={handleAddIngredient} className={styles.addIngredientButton}>
                  Legg til ingrediens
                </button>
              </div>
            )}
          </div> */}
          <div className={styles.addIngredientContainer}>
            <h4 className="my-4">Legg til ingrediens</h4>
            {!selectedIngredient ? (
              <>
                <input
                  type="text"
                  value={ingredientSearch}
                  onChange={handleIngredientSearch}
                  placeholder="Søk etter ingrediens"
                  className={styles.ingredientSearchInput}
                />
                <div className={styles.filteredIngredients}>
                  {filteredIngredients.map((ingredient) => (
                    <div
                      key={ingredient.id}
                      className={`${styles.ingredientOption} col-12 ${
                        selectedIngredient && selectedIngredient.id === ingredient.id ? styles.selected : ''
                      }`}
                      onClick={() => setSelectedIngredient(ingredient)}
                    >
                      {ingredient.attributes.name}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className={styles.addIngredientDetails}>
                <h5>Valgt ingrediens: {selectedIngredient.attributes.name}</h5>
                <div className="col-10 d-flex align-items-center justify-content-center mb-3">
                  <input
                    type="number"
                    value={newIngredientAmount}
                    onChange={(e) => setNewIngredientAmount(e.target.value)}
                    placeholder="Mengde"
                    className={`${styles.ingredientAmountInput} me-2`}
                  />
                  {newIngredientUnit}
                </div>
                <div className={styles.buttonGroup2}>
                  <button
                    onClick={() => {
                      handleAddIngredient();
                      setSelectedIngredient(null);
                      setIngredientSearch('');
                    }}
                    className={styles.addIngredientButton}
                  >
                    Legg til ingrediens
                  </button>
                  <button
                    onClick={() => setSelectedIngredient(null)}
                    className={styles.cancelButton}
                  >
                    Avbryt
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className={styles.macroSummary}>
            <h4>Totale Makroer</h4>
            <p>
              <strong>Protein:</strong> {totalMacros.protein}g
            </p>
            <p>
              <strong>Karbohydrater:</strong> {totalMacros.carbs}g
            </p>
            <p>
              <strong>Fett:</strong> {totalMacros.fat}g
            </p>
            <p>
              <strong>Kalorier:</strong> {totalMacros.kcals} kcal
            </p>
          </div>
          <div className={styles.nameInput}>
            <label>Navn på variant</label>
            <input
              className={styles.ingredientSearchInput}
              placeholder="Navn på varianten"
              value={newVariantName}
              onChange={handleVariantNameInput}
            />
          </div>
                </Modal.Body>
                <Modal.Footer>
                <button
                    className={styles.createButton}
                    onClick={() => createVariant({ variantName: newVariantName, ingredients: customIngredients })}
                    disabled={!newVariantName.trim()} // Disable if variant name is empty
                >
                    Opprett ny variant
                </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}