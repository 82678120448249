import React, { useState } from 'react';
import styles from './styles.module.scss';
// import { useIsMobile } from '../../../layouts/meals/coachSide';
// import BottomSheetModal from '../../../global/bottomSheetModal';
import { GetSingleSessionInfo } from '../../../../hooks/newHooks/coachSide/fetchClientSession';
import Loader from '../../../global/loader';
import WorkoutDetails from '../workoutDetails';
import {ReactComponent as RightArrow} from '../../../../images/icons/right-arrow.svg';
import ResponsiveModal from '../../../global/responsiveModal';

export default function SpecificClientWorkoutList({ workout }) {

    // const isMobile = useIsMobile();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedWorkout, setSelectedWorkout] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleWorkoutClick = async () => {
        setIsLoading(true);
        try {
            const workoutData = await GetSingleSessionInfo(workout.id);
            setSelectedWorkout(workoutData);
            setIsModalOpen(true);
            } catch (error) {
            console.error('Failed to fetch workout:', error);
        } finally{
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedWorkout(null);
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long' };
        return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateString));
    };

      //console.log(workout);

    if(isLoading) {
        return( <Loader />)
    }
        return(
            <>
                <div className={`${styles.card} col-11 p-3 d-flex align-items-center justify-content-between`} onClick={handleWorkoutClick}>
                    <div className="col-10 d-flex justify-content-between">
                        <div>{formatDate(workout?.attributes?.date_performed)}</div>
                        <div>{workout?.attributes?.client?.data?.attributes?.username}</div>
                    </div>
                    <div className="col-1">
                        <RightArrow style={{ fill: 'white' }}/>
                    </div>
                </div>

                <ResponsiveModal isOpen={isModalOpen} onClose={handleCloseModal}>
                    <WorkoutDetails workoutData={selectedWorkout} workoutId={workout.id}/>
                </ResponsiveModal>
            </>
        )
}