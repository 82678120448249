import { axiosAuthFetch } from "../../../helpers/axios";
import { API_GRAPHQL } from "../../../constants";

// Hent workouts
export async function GetWorkouts(clientId, limit = 5, start = 0) {
    const query = `
        query {
            usersPermissionsUser(id: "${clientId}") {
                data {
                    attributes {
                        workout_sessions(pagination: { limit: ${limit}, start: ${start} }, sort: "date_performed:desc") {
                            data {
                                id
                                attributes {
                                    date_performed
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
    const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'post');
    return response.data;
}

// Hent check-ins
export async function GetCheckIns(clientId, limit = 5, start = 0) {
    const query = `
        query {
            usersPermissionsUser(id: "${clientId}") {
                data {
                    attributes {
                        check_ins(pagination: { limit: ${limit}, start: ${start} }, sort: "date:desc") {
                            data {
                                id
                                attributes {
                                    date
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
    const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'post');
    return response.data;
}

// Hent meals
export async function GetMeals(clientId, limit = 5, start = 0) {
    const query = `
        query {
            usersPermissionsUser(id: "${clientId}") {
                data {
                    attributes {
                        meals(pagination: { limit: ${limit}, start: ${start} }) {
                            data {
                                id
                                attributes {
                                    name
                                }
                            }
                        }
                        meal_instances(pagination: { limit: ${limit}, start: ${start} }) {
                            data {
                                id
                                attributes {
                                    variant_name
                                    meal {
                                        data {
                                            id
                                            attributes {
                                                name
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
    const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'post');
    return response.data;
}

export async function GetPrograms(clientId, limit = 5, start = 0) {
    const query = `
        query {
            usersPermissionsUser(id: "${clientId}") {
                data {
                    attributes {
                        programs(pagination: { limit: ${limit}, start: ${start} }) {
                            data{
                                id
                                attributes{
                                    programName
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
    const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'post');
    return response.data;
}




// programs(pagination: { limit: 100 }){
//     data{
//         id
//         attributes{
//             programName
//             cover_image{
//                 data{
//                     id
//                     attributes{
//                         images{
//                             data{
//                                 id
//                                 attributes{
//                                     url
//                                 }
//                             }
//                         }
//                     }  
//                 }
//             }
//         }
//     }
// }