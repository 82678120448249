import React, { useState, useEffect } from 'react';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL } from '../../../../constants';       
import styles from './styles.module.scss';
//import Loader from '../../loader';
import { GetMealsAndInstances } from '../../../../hooks/newHooks/coachSide/createMealPlanQueries/getMealsAndInstances';
import BackUpImage from '../../../../images/mat.png';
import { getLocalStorageItem } from '../../storage';
import { ClientCard } from '../../../pages/specificMealPlanPage';
import CheckmarkIcon from '../../checkmarkIcon';

const userInfo = getLocalStorageItem('limitless-user');
const coachId = userInfo?.user?.coachID; // juster om nødvendig

export default function CreateMealPlanForm() {
  // ----- STATE-FELTER FOR SKJEMA -----
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  // eslint-disable-next-line
  const [totalKcals, setTotalKcals] = useState('');
  // eslint-disable-next-line
  const [protein, setProtein] = useState('');
  // eslint-disable-next-line
  const [carbs, setCarbs] = useState('');
  // eslint-disable-next-line
  const [fat, setFat] = useState('');
  
  const [goal, setGoal] = useState('');
  const [duration, setDuration] = useState('');
  const [notes, setNotes] = useState('');
  // eslint-disable-next-line
  const [tags, setTags] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);           // valgte Meals
  const [selectedMealInstances, setSelectedMealInstances] = useState([]); // valgte MealInstances
  const [allMeals, setAllMeals] = useState([]);        // for å liste ut alle mulige Meals
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [mealSearchTerm, setMealSearchTerm] = useState('');
  const [clientSearchTerm, setClientSearchTerm] = useState('');

  const filteredClients = clients.filter(client => 
    client.attributes.username.toLowerCase().includes(clientSearchTerm.toLowerCase())
  );

  const filteredMeals = allMeals.filter((meal) => {
    const mealName = meal.attributes.name.toLowerCase();
    return mealName.includes(mealSearchTerm.toLowerCase());
  });

  useEffect(() => {
    setIsLoading(true)
    async function fetchData() {
      try {
        const mealData = await GetMealsAndInstances(coachId);
        console.log('mealData:', mealData);

        setAllMeals(mealData.coach.data.attributes.meals.data);
        setClients(mealData.coach.data.attributes.clients.data);
        setIsLoading(false)
      } catch (error) {
        console.error("Feil ved henting av Meals og Instances:", error);
        setIsLoading(false);
      }
    }
  
    fetchData();
    // eslint-disable-next-line
  }, [coachId]);

  // ----- SUBMIT-FUNKSJON -----
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !description) {
      alert('Navn og beskrivelse er obligatorisk!');
      return;
    }

    setIsLoading(true);

    try {
      // 1. Beregn hvilke måltider som skal sendes med:
      // Bygg opp en mapping fra meal_instance-id til meal-id:
      const variantToMealMap = {};
      allMeals.forEach(meal => {
        const instances = meal.attributes.meal_instances?.data || [];
        instances.forEach(inst => {
          variantToMealMap[inst.id] = meal.id;
        });
      });
      
      // Filtrer ut de meal-id'ene der en variant er valgt.
      const finalMeals = selectedMeals.filter(mealId => {
        return !selectedMealInstances.some(instId => variantToMealMap[instId] === mealId);
      });

      // 2. Beregn makroverdier
      const CATEGORIES = ["Frokost", "Lunsj", "Middag", "Snack", "Mellommåltid", "Kveldsmat"];
      const planMacros = computePlanMacros(allMeals, selectedMeals, selectedMealInstances, CATEGORIES);
      
      const mealPlanData = {
        name,
        description,
        total_kcals: planMacros.totalKcals,
        protein: planMacros.totalProtein,
        carbs: planMacros.totalCarbs,
        fat: planMacros.totalFat,
        goal,
        duration,
        notes,
        tags,
        coach: coachId ? coachId : null,
        meals: finalMeals,  
        meal_instances: selectedMealInstances,
        clients: selectedClients,
      };

      const createResponse = await axiosAuthFetch(`${API_BASE_URL}/meal-plans`, 
        { data: mealPlanData }, 
        'POST'
      );
      
      console.log('Opprettet MealPlan:', createResponse);
      alert('MealPlan opprettet!');

      // Reset skjema
      setName('');
      setDescription('');
      setTotalKcals('');
      setProtein('');
      setCarbs('');
      setFat('');
      setGoal('');
      setDuration('');
      setNotes('');
      setTags([]);
      setSelectedMeals([]);
      setSelectedMealInstances([]);
      setSelectedClients([]);
    } catch (error) {
      console.error('Feil ved opprettelse av MealPlan:', error);
      alert('Kunne ikke opprette MealPlan. Sjekk konsollen for mer info.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleMealSelection = (mealId) => {
    setSelectedMeals((prev) => {
      if (prev.includes(mealId)) {
        return prev.filter((id) => id !== mealId);
      } else {
        return [...prev, mealId];
      }
    });
  };

  const toggleClientSelection = (clientId) => {
    setSelectedClients((prev) => {
      if (prev.includes(clientId)) {
        return prev.filter((id) => id !== clientId);
      } else {
        return [...prev, clientId];
      }
    });
  };

//   if (isLoading) {
//     return <Loader />;
//   }

  // Funksjon for å beregne makroverdier basert på valgte måltider og varianter
  function computePlanMacros(allMeals, selectedMeals, selectedMealInstances, categories) {
    let totalProtein = 0;
    let totalCarbs   = 0;
    let totalFat     = 0;
    let totalKcals   = 0;
  
    categories.forEach((category) => {
      const mealForCategory = selectedMeals
        .map((mealId) => allMeals.find(m => m.id === mealId && m.attributes.category === category))
        .find(m => m != null);
  
      if (mealForCategory) {
        const instances = mealForCategory.attributes.meal_instances?.data || [];
        const selectedInstance = instances.find(inst => selectedMealInstances.includes(inst.id));
  
        if (selectedInstance) {
          const instAttrs = selectedInstance.attributes;
          totalProtein += instAttrs.proteins || 0;
          totalCarbs   += instAttrs.carbs   || 0;
          totalFat     += instAttrs.fats    || 0;
          totalKcals   += instAttrs.kcals   || 0;
        } else {
          const mealAttrs = mealForCategory.attributes;
          totalProtein += mealAttrs.proteins || 0;
          totalCarbs   += mealAttrs.carbs   || 0;
          totalFat     += mealAttrs.fats    || 0;
          totalKcals   += mealAttrs.kcals   || 0;
        }
      }
    });
  
    return {
      totalProtein,
      totalCarbs,
      totalFat,
      totalKcals,
    };
  }

  const CATEGORIES = ["Frokost", "Lunsj", "Middag", "Snack", "Mellommåltid", "Kveldsmat"];
  const planMacros = computePlanMacros(allMeals, selectedMeals, selectedMealInstances, CATEGORIES);

  return (
    <div className={`${styles.wrapper} ${styles.form} col-12 d-flex flex-column align-items-center`}>
        {isLoading && (<div className={styles.lasteTekst}>Laster...</div>)}
      {/* Søkefelt */}
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Søk etter måltid ..."
          value={mealSearchTerm}
          onChange={(e) => setMealSearchTerm(e.target.value)}
          className={`${styles.input} p-2 rounded-4`}
        />
      </div>

      {/* Loop over kategoriene */}
      {CATEGORIES.map((cat) => {
        const mealsInCategory = filteredMeals.filter(
          (meal) => meal.attributes.category === cat
        );

        if (mealsInCategory.length === 0) {
          return null;
        }

        return (
          <div key={cat} className={styles.categorySection}>
            <h2 className={`${styles.categoryTitle} text-center`}>{cat}</h2>
            <div className={styles.horizontalScrollContainer}>
              {mealsInCategory.map((meal) => {
                const mealId = meal.id;
                const mealName = meal.attributes.name;
                const isSelected = selectedMeals.includes(mealId);

                // Hent bilde-URL
                const mealImages = meal.attributes.meal_images?.data;
                const imageUrl =
                  mealImages && mealImages.length > 0
                    ? mealImages[0]?.attributes?.image?.data?.attributes?.url
                    : BackUpImage;

                const backgroundGradient = isSelected
                  ? 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))'
                  : 'linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0))';

                // Funksjon som sørger for at kun én variant per måltid kan velges.
                const toggleInstanceSelectionForMeal = (instanceId) => {
                  setSelectedMealInstances((prev) => {
                    // Finn alle instance-ID-er for dette måltidet:
                    const mealInstanceIds = (meal.attributes.meal_instances?.data || []).map(
                      (inst) => inst.id
                    );
                    // Fjern alle valg for dette måltidet:
                    let newSelected = prev.filter((id) => !mealInstanceIds.includes(id));

                    // Dersom den trykte instance ikke allerede var valgt, legg den til:
                    if (!prev.includes(instanceId)) {
                      newSelected.push(instanceId);
                    }
                    return newSelected;
                  });
                };

                return (
                  <div key={mealId} onClick={() => toggleMealSelection(mealId)}>
                    <div 
                      className={`${styles.mealCard} ${isSelected ? styles.selected : ""}`}
                      style={{
                        backgroundImage: `${backgroundGradient}, url(${imageUrl})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    >
                      {isSelected && (
                        <div className={styles.overlayCheck}>
                          <span className={styles.checkmark}><CheckmarkIcon/></span>
                        </div>
                      )}
                    </div>
                    <h4 className="text-center mt-2">{mealName}</h4>
                    {isSelected && meal.attributes.meal_instances?.data?.length > 0 && (
                      <div className={styles.instanceList}>
                        <strong>Varianter:</strong>
                        {meal.attributes.meal_instances.data.map((inst) => {
                          const instId = inst.id;
                          const variantName = inst.attributes.variant_name || `Instance #${instId}`;
                          const isInstSelected = selectedMealInstances.includes(instId);
                          return (
                            <div
                              key={instId}
                              className={`${styles.instanceItem} ${isInstSelected ? styles.instSelected : ""}`}
                              onClick={(e) => {
                                e.stopPropagation(); 
                                toggleInstanceSelectionForMeal(instId);
                              }}
                            >
                              <span>{variantName}</span>
                              {isInstSelected && (
                                <span className={styles.instanceCheckmark}><CheckmarkIcon/></span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}

      <div className={`${styles.macroOverview} d-flex flex-column align-items-center`}>
        <h3 className="text-center">Daglig Makrooversikt (basert på første valg per kategori)</h3>
        <p>Protein: {planMacros.totalProtein} g</p>
        <p>Karbohydrater: {planMacros.totalCarbs} g</p>
        <p>Fett: {planMacros.totalFat} g</p>
        <p>Kalorier: {planMacros.totalKcals} kcal</p>
      </div>

      <form
        className={`${styles.form} col-10 col-md-12 d-flex flex-column align-items-center`}
        onSubmit={handleSubmit}
      >
        <input
          className={`${styles.input} col-10 p-2 rounded-4`}
          type="text"
          placeholder="Navn på planen"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <textarea
          className={`${styles.textArea} col-10 p-2 rounded-4`}
          placeholder="Beskrivelse"
          rows={6}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <input
          className={`${styles.input} col-10 p-2 rounded-4`}
          type="text"
          placeholder="Mål (f.eks vektnedgang, muskelbygging)"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
        />
        <input
          className={`${styles.input} col-10 p-2 rounded-4`}
          type="text"
          placeholder="Varighet (f.eks. 4 uker)"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
        />
        <textarea
          className={`${styles.input} col-10 p-2 rounded-4`}
          placeholder="Notater (internt)"
          value={notes}
          rows={6}
          onChange={(e) => setNotes(e.target.value)}
        />
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Søk etter klient ..."
            value={clientSearchTerm}
            onChange={(e) => setClientSearchTerm(e.target.value)}
            className={`rounded-5 ${styles.input} p-3`}
          />
        </div>
        <div className={styles.clientsContainer}>
          {filteredClients.map((client) => {
            const isSelected = selectedClients.includes(client.id);
            return(
            <div
              key={client.id}
              className={`${styles.clientCard} ${
                selectedClients.includes(client.id) ? styles.selectedClient : ""
              }`}
              onClick={() => toggleClientSelection(client.id)}
              style={{ position: 'relative' }}
            >
              <ClientCard client={client}/>
              {isSelected && (
                    <div className={styles.overlayCheck}>
                    <span className={styles.checkmark}><CheckmarkIcon /></span>
                    </div>
                )}
            </div>
            )})}
        </div>
        <button className={`${styles.submitButton} col-10`} type="submit">
          Lagre matplan
        </button>
      </form>
    </div>
  );
}
