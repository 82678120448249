import React from 'react';
import BottomSheetModal from '../bottomSheetModal';
import CustomAlert from '../customAlert';
import { useIsMobile } from '../../layouts/meals/coachSide'; 

export default function ResponsiveModal({
  isOpen,
  onClose,
  title,
  buttonLabel = 'Lukk',
  children
}) {
  const isMobile = useIsMobile();

  if (!isOpen) return null;

  // Hvis mobil -> BottomSheetModal
  // Hvis desktop -> CustomAlert
  if (isMobile) {
    return (
      <BottomSheetModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        onBackdropClick={onClose}
      >
        {children}
      </BottomSheetModal>
    );
  }

  // Desktop
  return (
    <CustomAlert
      show={isOpen}
      handleClose={onClose}
      buttonLabel={buttonLabel}
      title={title}
      content={children}
    />
  );
}