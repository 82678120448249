import React, { useState } from 'react';
import styles from './styles.module.scss';
import { API_BASE_URL } from '../../../../constants';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { getLocalStorageItem } from '../../../global/storage';

export default function WorkoutDetails({ workoutData, workoutId }) {

    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo.user;
    //eslint-disable-next-line
    const [isCoach, setIsCoach] = useState(user.isCoach);
    const [coachComment, setCoachComment] = useState(workoutData?.coach_comments);
    console.log(isCoach);

    if (!workoutData) return null; // Sikkerhetsjekk

    
  
    // Her er "workoutData.attributes" = alt du trenger
    const attrs = workoutData;
  
    // Hent exercise_logs-arrayen
    const logsArray = attrs?.exercise_logs?.data || [];
  
    // Kjør grouping
    const groupedLogs = groupExerciseLogsByOrder(logsArray);
  
    // Regn ut total løftet vekt om du ønsker
    const totalWeight = logsArray.reduce((sum, log) => {
      return sum + log.attributes.reps * log.attributes.vekt;
    }, 0);

    const handleSaveComment = async () => {
            //console.log("Saved comment for check-in ID:", currentWorkout.id, "Comment:", coachComment);
            const url = `${API_BASE_URL}/workout-sessions/${workoutId}`;
            const response = await axiosAuthFetch(url, {data:{
                    coach_comments: coachComment
            }}, 'PUT');
            console.log(response);
            alert('Operasjonen er vellykket!')
            window.location.reload();
        }

    const bgImage = workoutData?.program?.data?.attributes?.cover_image?.data?.attributes?.images?.data[0]?.attributes?.url || 'https://meaty-thin-quilt.media.strapiapp.com/deadlift_ab8fa1ca83.jpg';
  
    // Kopier design fra gammel koden
    return (
      <div className={`${styles.modalContent} m-auto col-11 d-flex flex-column align-items-center`}>
        {/* Header */}
        <div className={styles.headerContainer} style={{ backgroundImage: `url(${bgImage})` }}>
          <div className={styles.headerNavn}>{attrs.name}</div>
          <div className={styles.timerContainer}>
            <div className={styles.timer}>
              Tid: {attrs.duration || 'N/A'}
            </div>
          </div>
        </div>
  
        {/* Grupperte øvelser */}
        {groupedLogs.map((exerciseData, index) => (
          <div key={index} className={styles.exerciseCard}>
            <div className={styles.cardHeader}>
              <div
                className={styles.cardHeaderImageContainer}
                style={{ backgroundImage: `url(${exerciseData.exerciseImageUrl})` }}
              />
              <div className={styles.exerciseNameDiv}>
                <p className={styles.exerciseName}>{exerciseData.exerciseName}</p>
              </div>
            </div>
            <div className={styles.subHeader}>
              <div className={styles.subSet}>Sett</div>
              <div className={styles.subReps}>Reps utført</div>
              <div className={styles.subReps}>Vekt</div>
            </div>
            {exerciseData.sets.map((set, setIndex) => (
              <div key={setIndex} className={styles.set}>
                {set.sett}.
                <div className={styles.presetReps}>{set.reps}</div>
                <div className={styles.presetReps}>{set.vekt} kg</div>
              </div>
            ))}
          </div>
        ))}
  
        {/* Rating, kommentar, total løftet, etc. */}
        <div className={styles.inputRating}>
          <label>Klients rating av økt:</label>
          {/* Bytt ut med renderStars(attrs.rating) hvis du vil */}
          <p>{renderStars(attrs.rating) || 'N/A'}</p>
        </div>
  
        <div className={styles.clientComment}>
          <strong>Klients kommentar: </strong>
          {attrs.clients_comments || 'Ingen kommentar'}
        </div>
  
        <div className={styles.totalWeight}>
          <p>Total vekt løftet: {totalWeight} kg</p>
        </div>
        <p className="mt-5"><strong>Din kommentar: </strong>{coachComment}</p>
        {isCoach && (
        <>
          <textarea
            className={styles.coachCommentTextarea}
            value={coachComment}
            onChange={(e) => setCoachComment(e.target.value)}
            placeholder="Skriv din kommentar her"
            rows="6"
          />
          <div
            className={`${styles.saveButton} col-10 text-center rounded-5 my-3 bg-primary p-2`}
            onClick={handleSaveComment}
          >
            Lagre
          </div>
        </>
      )}
      </div>
    );
  }

  function groupExerciseLogsByOrder(exerciseLogs) {
    const groupedExercises = {};
  
    exerciseLogs.forEach((log) => {
      const exerciseId = log.attributes.exercise.data.id;
      const exerciseName = log.attributes.exercise.data.attributes.name;
      const exerciseImageUrl =
        log.attributes.exercise.data.attributes.image?.data?.[0]?.attributes?.url || '';
      const order = log.attributes.order;
  
      if (!groupedExercises[exerciseId]) {
        groupedExercises[exerciseId] = {
          exerciseName,
          exerciseImageUrl,
          order,
          sets: [],
        };
      }
  
      groupedExercises[exerciseId].sets.push({
        sett: log.attributes.sett,
        reps: log.attributes.reps,
        vekt: log.attributes.vekt,
      });
    });
  
    // Sortér på 'order'-feltet
    return Object.values(groupedExercises).sort((a, b) => a.order - b.order);
  }

  const renderStars = (rating) => {
    const stars= [];
    for (let i= 1; i <= 5; i++) {
        stars.push(
            <span key={i} className={i <= rating ? styles.starFilled : styles.starEmpty}>&#9733;</span>
        );
    }
    return stars;
}