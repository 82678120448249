import React from 'react';
import styles from './styles.module.scss';
import meals from '../../../images/mealPage/meals.jpg';
import plans from '../../../images/mealPage/plans.jpg';
import ingredients from '../../../images/mealPage/ingredients.jpg';
import { useNavigate } from 'react-router';
import { getLocalStorageItem } from '../../global/storage';
import MealPageLayoutClientSide from '../../layouts/meals/clientSide';

export default function MealsHome() {

    const navigate = useNavigate();

    const navigateToMeals = () => {
        navigate('/meals/home');
    }

    const navigateToIngredients = () => {
        navigate('/meals/ingredients');
    }

    const navigateToPlans = () => {
        navigate('/mealplan');
    }

    const user = getLocalStorageItem('limitless-user');
    
        const isCoach = user.user.isCoach;
        console.log(user);
    
        if(!isCoach) {
            return(
                <MealPageLayoutClientSide />
            )
        };

    return(
        <div className={`${styles.container} appearing-container`}>
            <div className={`${styles.card} col-10 col-md-3 shadow-lg`} style={{ backgroundImage: `url(${meals})` }} onClick={navigateToMeals}>
                <div className={styles.overlay}></div>
                <div className={styles.content}>Måltider</div>
            </div>
            <div className={`${styles.card} col-10 col-md-3 shadow-lg`} style={{ backgroundImage: `url(${plans})` }} onClick={navigateToPlans}>
                <div className={styles.overlay}></div>
                <div className={styles.content}>Matplaner</div>
            </div>
            <div className={`${styles.card} col-10 col-md-3 shadow-lg`} style={{ backgroundImage: `url(${ingredients})` }} onClick={navigateToIngredients}>
                <div className={styles.overlay}></div>
                <div className={styles.content}>Ingredienser</div>
            </div>
        </div>
    )
}