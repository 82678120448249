import React, {useState, useEffect} from 'react';
import styles from './styles.module.css';
import CreateMealForm from '../../../global/forms/createMeal';
//import MealCard from '../../../global/mealCards/coachSide';
import { getLocalStorageItem } from '../../../global/storage';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_GRAPHQL } from '../../../../constants';
import CoachMealDetail from '../detail/coachSide';
import CoachSideMealCard from '../cards/coachSide';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../../transitions.css';
import { useNavigate } from 'react-router';
import Loader from '../../../global/loader';

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}


export default function MealPageLayoutCoachSide() {

    const navigate = useNavigate();
    const [meals, setMeals] = useState([]);
    const [clients, setClients] = useState([]);
    const userInfo = getLocalStorageItem('limitless-user');
    const coachId = userInfo.user.coachID;
    const [selectedMeal, setSelectedMeal] = useState(null);
    //eslint-disable-next-line
    const [ingredients, setIngredients] = useState([]);
    const [isNavigatingForward, setIsNavigatingForward] = useState(true);
    const isMobile = useIsMobile();
    const [isLoading, setIsLoading] = useState(false);
    const [mealImages, setMealImages] = useState([]);

    useEffect(() => {
      setIsLoading(true);
      const fetchAllData = async () => {
        const query = `
          query {
            mealImages{
              data{
                id
                attributes{
                  image{
                    data{
                      id
                      attributes{
                        url
                      }
                    }
                  }
                }
              }
            }
            meals(filters: { coaches: { id: { eq: ${coachId} } } }, pagination: { limit: 50 }) {
              data {
                id
                attributes {
                  name
                  category
                  meal_images {
                    data {
                      attributes {
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  clients(pagination: {limit: 50}) {
                    data {
                      id
                      attributes {
                        username
                      }
                    }
                  }
                }
              }
            }
           
            coaches(filters: { id: { eq: ${coachId} } }) {
              data {
                id
                attributes {
                  clients(pagination: {limit: 50}) {
                    data {
                      id
                      attributes {
                        username
                      }
                    }
                  }
                }
              }
            }
          }
        `;
        
        try {
          const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'POST');
          
          // Sett måltider
          setMeals(response.data?.meals?.data);
          
          // Sett ingredienser
          //setIngredients(response.data?.ingredients?.data);

          setMealImages(response.data?.mealImages?.data);
          
          // Sett klienter (filter ut nåværende bruker)
          const clientsData = response.data?.coaches?.data?.[0]?.attributes?.clients?.data || [];
          const userInfo = getLocalStorageItem('limitless-user');
          const userID = userInfo.user.id;
          const filteredClients = clientsData.filter(client => client.id !== userID);
          setClients(filteredClients);
          
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      };
      
      fetchAllData();
    }, [coachId]);

    // const handleMealClick = (meal) => {
    //     setIsNavigatingForward(true); 
    //     setSelectedMeal(meal);
    // };
    
    const redirectToSpecificMealPage = (id) => {
      navigate(`/meal/${id}`);
    }

    // Funksjon for å gå tilbake til listen
    const handleBack = () => {
        setIsNavigatingForward(false); // Navigerer bakover
        setSelectedMeal(null);
    };

    const [searchText, setSearchText] = useState('');

    const filteredMeals = meals.filter((meal) =>
      meal.attributes.name.toLowerCase().includes(searchText.toLowerCase())
    );

    if(isLoading) {
      return(
        <> <Loader/> </>
      )
    }
    
    return (
      <div className={`${styles.container} appearing-container`}>
        {isMobile ? (
          <TransitionGroup component={null}>
            <CSSTransition
              key={selectedMeal ? selectedMeal.id : 'list'}
              classNames={isNavigatingForward ? 'slide-forward' : 'slide-backward'}
              timeout={250}
            >
              {selectedMeal ? (
                <CoachMealDetail
                  clients={clients}
                  allIngredients={ingredients}
                  onBack={handleBack}
                />
              ) : (
                <>
                  <div className={styles.child1}>
                    <h2 className="text-center">Dine måltider</h2>
                    <div className="col-6 col-md-6 mx-auto mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Søk etter måltid..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)} // Oppdater søketekst
                      />
                    </div>
                    <div className={styles.cardContainer}>
                      {filteredMeals.length > 0 ? (
                        filteredMeals.map((meal) => (
                          <CoachSideMealCard
                            key={meal.id}
                            meal={meal}
                            clients={clients}
                            coachId={coachId}
                            onClick={() => redirectToSpecificMealPage(meal.id)}
                            isDesktop={!isMobile}
                          />
                        ))
                      ) : (
                        <p className="text-center text-muted">Ingen måltider funnet.</p>
                      )}
                    </div>
                  </div>
                  <div className={styles.child2}>
                    <CreateMealForm mealImageArray={mealImages} ingredientsArray={ingredients} />
                  </div>
                </>
              )}
            </CSSTransition>
          </TransitionGroup>
        ) : (
          <div className={styles.container}>
            {selectedMeal ? (
                <>
                  <CoachMealDetail  
                      clients={clients} 
                      allIngredients={ingredients} 
                      onBack={handleBack}
                      isDesktop={!isMobile}
                    />
                </>
                // <div className={`${styles.detailContent} ${styles.overflowY}`}>
                // </div>
              ): (
                <>
                  <div className={`col-4 d-flex flex-column align-items-center ${styles.overflowY}`}>
                    <h2 className="text-center">Dine måltider</h2>
                    <div className="col-12 col-md-6 mx-auto mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Søk etter måltid..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)} // Oppdater søketekst
                      />
                    </div>
                    <div className={styles.cardContainer}>
                    {filteredMeals.length > 0 ? (
                      filteredMeals.map((meal) => (
                        <CoachSideMealCard
                          key={meal.id}
                          meal={meal}
                          clients={clients}
                          coachId={coachId}
                          onClick={() => redirectToSpecificMealPage(meal.id)}
                          isDesktop={!isMobile}
                        />
                      ))
                  ) : (
                    <p className="text-center text-muted">Ingen måltider funnet.</p>
                  )}
                  </div>
                </div>
              <div className={`col-4 d-flex flex-column align-items-center ${styles.overflowY}`}>
                <div className={styles.child3}>
                  <CreateMealForm mealImageArray={mealImages} ingredientsArray={ingredients} />
                </div>
              </div>
                </>
              )}
            </div>
        )}
      </div>
    );
}