import { axiosAuthFetch } from "../../../helpers/axios";
import { API_GRAPHQL } from "../../../constants";


export async function GetSingleCheckInInfo(id) {

const query = `
    query {
        checkIn(id: "${id}"){
            data{
                id
                attributes{
                    date
                    comment
                    rating_training
                    rating_sleep
                    rating_motivation
                    rating_nutrition
                    weight
                    coach_comment
                    progress_pic{
                        data{
                            attributes{
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`

const response = await axiosAuthFetch( API_GRAPHQL, { query }, 'post');

return response.data.checkIn.data;

}