import { API_GRAPHQL } from '../../constants';
import { axiosAuthFetch } from '../../helpers/axios';

export async function GetSingleMealInfo(id) {

    const query = `
        query{
            meal(id:${id}){
                data{
                    id
                    attributes{
                        name
                        description
                        prep_time
                        cooking_time
                        serving
                        cooking_instructions
                        preparation
                        category
                        meal_instances{
                            data{
                                id
                                attributes{
                                    custom_ingredients
                                    variant_name
                                    clients{
                                        data{
                                            id
                                            attributes{
                                                username
                                                profile_picture{
                                                    data{
                                                        id
                                                        attributes{
                                                            url
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        meal_ingredients{
                            data{
                                id
                                attributes{
                                    unit
                                    amount
                                    ingredients{
                                        data{
                                            id
                                            attributes{
                                                name
                                                macros{
                                                    protein
                                                    fat
                                                    carbs
                                                    kcals
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        meal_images{
                            data{
                                attributes{
                                    image{
                                        data{
                                            attributes{
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        clients(pagination: {limit: 50}) {
                            data{
                                id
                                attributes{
                                    username
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'post');
    
    return(response.data.meal.data);

}