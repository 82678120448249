import { axiosAuthFetch } from "../../../helpers/axios";
import { API_GRAPHQL } from "../../../constants";


export async function GetSingleClientInfo(id) {

const query = `
    query {
        usersPermissionsUser(id: "${id}") {
            data {
                id
                attributes {
                    username
                    profile_picture{
                        data{
                            id
                            attributes{
                                url
                            }
                        }
                    }
                    programs(pagination: { limit: 5 }){
                        data{
                            id
                            attributes{
                                programName
                            }
                        }
                    }
                    meal_plans{
                        data{
                            id
                            attributes{
                                name
                                total_kcals
                                fat
                                protein
                                carbs
                            }
                        }
                    }
                    workout_sessions(pagination: { limit: 5 }, sort:"date_performed:desc"){
                            data{
                                id
                                attributes{
                                    date_performed
                                }
                            }
                        }
                    check_ins(pagination: {limit: 5}, sort:"date:desc"){
                        data{
                            id
                            attributes{
                                date
                            }
                        }
                    }
                }
            }
        }
    }
`

const response = await axiosAuthFetch( API_GRAPHQL, { query }, 'post')
return response.data;

}


export async function getClients(id) {
    const query = `
        query{
            coach(id:"${id}"){
                data{
                id
                attributes{
                    clients{
                    data{
                        id
                        attributes{
                            username
                            meal_plans{
                                data{
                                    id
                                }
                            }
                        }
                    }
                    }
                }
                }
            }
        }
    `;

    const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'post');

    return response.data;
}