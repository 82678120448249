import { useState, useEffect} from 'react';
import { getLocalStorageItem } from '../../components/global/storage';
import { API_GRAPHQL } from '../../constants';
import { axiosAuthFetch } from '../../helpers/axios';

export function useClientInformation() {
    const user = getLocalStorageItem('limitless-user');
    const coachID = user.user.coachID;
    const url = API_GRAPHQL;
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [clients, setClients] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [checkIns, setCheckIns] = useState([]);

    useEffect(() => {
        async function getPrograms(url) {

            const query = `query {
                workoutSessions(filters: {client: {coach: {id: {eq:${coachID}}}}}, pagination: { limit: 5 }, sort:"date_performed:desc"){
                    data{
                        id
                        attributes{
                            date_performed
                            client{
                                data{
                                    id
                                    attributes{
                                        username
                                    }
                                }
                            }
                        }
                    }
                }
                checkIns(filters: {client: {coach: {id: {eq:${coachID}}}}}, pagination: {limit: 5}, sort:"date:desc"){
                    data{
                        id
                        attributes{
                            date
                            client{
                                data{
                                    id
                                    attributes{
                                        username
                                    }
                                }
                            }
                        }
                    }
                }
                coach(id:${coachID}){
                    data{
                        id
                        attributes{
                            clients(pagination: { limit: 100 }) {
                                data{
                                    id
                                    attributes{
                                        username
                                        blocked
                                        profile_picture{
                                            data{
                                                id
                                                attributes{
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`;
            ;
            try {
                setIsError(false);
                setIsLoading(true);

                const response = await axiosAuthFetch(url, { query }, 'post')
                console.log(response);
                setSessions(response.data.workoutSessions.data);
                setClients(response.data.coach.data.attributes.clients.data);
                setCheckIns(response.data.checkIns.data);
            } catch(error) {
                console.error(error);
            }
            finally {
                setIsLoading(false);
            }
        }

        getPrograms(url)
    }, [coachID, url])

    return {clients, checkIns, sessions, isLoading, isError};

}