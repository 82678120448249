// import React, { useState, useEffect } from 'react';
// import { axiosAuthFetch } from '../../../../helpers/axios';
// import { API_BASE_URL } from '../../../../constants';
// import styles from './styles.module.css';
// import { getLocalStorageItem } from '../../storage';

// export default function CreateMealForm() {
//     const [ingredients, setIngredients] = useState([]);
//     const [selectedIngredients, setSelectedIngredients] = useState([]);
//     const [name, setName] = useState('');
//     const [description, setDescription] = useState('');
//     const [prepTime, setPrepTime] = useState();
//     const [cookingTime, setCookingTime] = useState();
//     const [serving, setServing] = useState();
//     const [category, setCategory] = useState('');
//     const [cookingInstructions, setCookingInstructions] = useState('');
//     const [preparation, setPreparation] = useState('');
//     const [mealImages, setMealImages] = useState([]);
//     const [selectedImage, setSelectedImage] = useState(''); // Velg bilde fra eksisterende alternativer
//     const [searchText, setSearchText] = useState('');
//     const [filteredIngredients, setFilteredIngredients] = useState([]);
//     const [ingredientAmounts, setIngredientAmounts] = useState({});
//     const userInfo = getLocalStorageItem('limitless-user');
//     const coachId = userInfo.user.coachID;

//     // Hent ingredienser og måltidsbilder fra Strapi
//     useEffect(() => {
//         async function fetchData() {
//             try {
//                 const ingredientResponse = await axiosAuthFetch(`${API_BASE_URL}/ingredients?populate=*`);
//                 setIngredients(ingredientResponse.data.map(item => ({ ...item.attributes, id: item.id })));

//                 const imageResponse = await axiosAuthFetch(`${API_BASE_URL}/meal-images?populate=*`);
//                 setMealImages(imageResponse.data.map(item => ({ id: item.id, url: item.attributes.image.data.attributes.url })));
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         }
//         fetchData();
//     }, []);

//     const handleSearchChange = (e) => {
//         const searchValue = e.target.value.toLowerCase();
//         setSearchText(searchValue);
    
//         if (searchValue === '') {
//             // Hvis søkefeltet er tomt, vis alle ingrediensene igjen
//             setFilteredIngredients([]);
//         } else {
//             // Filtrer ingrediensene basert på søket
//             const filtered = ingredients.filter(ingredient =>
//                 ingredient.name.toLowerCase().includes(searchValue)
//             );
//             setFilteredIngredients(filtered);
//         }
//     };

//     const handleIngredientChange = (ingredientId, amount) => {
//         setIngredientAmounts(prev => ({
//             ...prev,
//             [ingredientId]: amount
//         }));
//     };

//     const handleAddIngredient = (ingredientId, unit) => {
//         const amount = ingredientAmounts[ingredientId];
//         const ingredient = ingredients.find(ing => ing.id === ingredientId);
//         if (ingredient && amount) {
//             setSelectedIngredients(prevIngredients => [
//                 ...prevIngredients,
//                 { ...ingredient, amount, unit }
//             ]);
//             setFilteredIngredients(prev => prev.filter(ing => ing.id !== ingredientId));
//         }
//     };

//     const handleSubmit = async () => {
//         try {
//             const mealResponse = await axiosAuthFetch(`${API_BASE_URL}/meals`, {
//                 data: {
//                     name,
//                     description,
//                     prep_time: prepTime,
//                     cooking_time: cookingTime,
//                     serving,
//                     category,
//                     coaches: [coachId],
//                     cooking_instructions: cookingInstructions,
//                     preparation,
//                     meal_images: selectedImage ? [{ id: selectedImage }] : [],
//                 }
//             }, 'POST');

//             console.log(mealResponse);

//             const mealID = mealResponse.data.id;

//             await Promise.all(selectedIngredients.map(async (ingredient) => {
//                 await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients`, {
//                     data: {
//                         meals: [mealID],
//                         ingredients: [ingredient.id],
//                         amount: ingredient.amount,
//                         unit: ingredient.unit,
//                     }
//                 }, 'POST');
//             }));

//             alert('Måltid opprettet!');
//         } catch (error) {
//             console.error('Error creating meal:', error);
//             alert('Kunne ikke opprette måltid. Vennligst prøv igjen.');
//         }
//     };

//     return (
//         <div className={styles.container}>
//             <h2>Opprett nytt måltid</h2>
//             <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
//                 <input
//                     className={styles.input}
//                     type="text"
//                     placeholder="Navn"
//                     value={name}
//                     onChange={(e) => setName(e.target.value)}
//                     required
//                 />
//                 <textarea
//                     className={styles.textArea}
//                     placeholder="Beskrivelse"
//                     value={description}
//                     onChange={(e) => setDescription(e.target.value)}
//                 />
//                 <input
//                     className={styles.input}
//                     type="number"
//                     placeholder="Forberedelsestid (minutter)"
//                     value={prepTime}
//                     onChange={(e) => setPrepTime(e.target.value)}
//                     required
//                 />
//                 <input
//                     className={styles.input}
//                     type="number"
//                     placeholder="Tid å lage (minutter)"
//                     value={cookingTime}
//                     onChange={(e) => setCookingTime(e.target.value)}
//                     required
//                 />
//                 <input
//                     className={styles.input}
//                     type="number"
//                     placeholder="Antall porsjoner"
//                     value={serving}
//                     onChange={(e) => setServing(e.target.value)}
//                     required
//                 />
//                 <select
//                     className={styles.select}
//                     value={category}
//                     onChange={(e) => setCategory(e.target.value)}
//                     required
//                 >
//                     <option value="">Velg kategori</option>
//                     <option value="Frokost">Frokost</option>
//                     <option value="Lunsj">Lunsj</option>
//                     <option value="Middag">Middag</option>
//                     <option value="Kveldsmat">Kveldsmat</option>
//                     <option value="Mellommåltid">Mellommåltid</option>
//                     <option value="Snack">Snack</option>
//                 </select>
//                 <textarea
//                     className={styles.textArea}
//                     placeholder="Instruksjoner"
//                     value={cookingInstructions}
//                     onChange={(e) => setCookingInstructions(e.target.value)}
//                 />
//                 <textarea
//                     className={styles.textArea}
//                     placeholder="Preparation"
//                     value={preparation}
//                     onChange={(e) => setPreparation(e.target.value)}
//                 />

//                 <h3>Valgte Ingredienser</h3>
//                 <ul className={styles.selectedIngredientList}>
//                     {selectedIngredients.map(ingredient => (
//                         <li key={ingredient.id} className={styles.selectedIngredientItem}>
//                             {ingredient.name}: {ingredient.amount} {ingredient.unit} - {ingredient.macros[0]?.kcals || 0} kcal
//                         </li>
//                     ))}
//                 </ul>

//                 <h3>Ingredienser</h3>
//                 <input
//                     type="text"
//                     placeholder="Søk etter ingrediens"
//                     value={searchText}
//                     onChange={handleSearchChange}
//                     className={styles.input}
//                 />
//                 {filteredIngredients.map(ingredient => (
//                     <div key={ingredient.id} className={styles.ingredientRow}>
//                         <label>{ingredient.name} ({ingredient.unit})</label>
//                         <div className={styles.ingredientSub}>
//                             <input
//                                 className={styles.input}
//                                 type="number"
//                                 placeholder={`Mengde (${ingredient.unit})`}
//                                 onChange={(e) => handleIngredientChange(ingredient.id, e.target.value, ingredient.unit)}
//                             />
//                             <button className={styles.addButton} onClick={() => handleAddIngredient(ingredient.id, ingredient.unit)}>
//                                 Legg til
//                             </button>
//                         </div>
//                     </div>
//                 ))}

//                 <h3>Velg et bilde</h3>
//                     <div className={styles.imageScrollContainer}>
//                         {mealImages.map(image => (
//                             <div 
//                                 key={image.id} 
//                                 className={`${styles.imageOption} ${selectedImage === image.id ? styles.selected : ''}`}
//                                 onClick={() => setSelectedImage(image.id)}
//                             >
//                                 <img src={image.url} alt="Meal" className={`${styles.imageThumbnail} ${selectedImage === image.id ? styles.imageSelected : ''}`} />
//                                 {selectedImage === image.id && (
//                                     <div className={styles.overlay}>
//                                         <span className={styles.checkmark}>✔</span>
//                                     </div>
//                                 )}
//                             </div>
//                         ))}
//                     </div>

//                 <button className={styles.submitButton} onClick={handleSubmit}>Lagre Måltid</button>
//             </form>
//         </div>
//     );
// }

import React, { useState, useEffect } from 'react';
import { axiosAuthFetch, axiosImageAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL } from '../../../../constants';
import styles from './styles.module.css';
import { getLocalStorageItem } from '../../storage';
import Loader from '../../loader';

export default function CreateMealForm({ingredientsArray, mealImageArray}) {
    
    const [ingredients, setIngredients] = useState([]);
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [prepTime, setPrepTime] = useState('');
    const [cookingTime, setCookingTime] = useState('');
    const [serving, setServing] = useState('');
    const [category, setCategory] = useState('');
    const [cookingInstructions, setCookingInstructions] = useState('');
    const [preparation, setPreparation] = useState('');
    const [mealImages, setMealImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(''); // Velg bilde fra eksisterende alternativer
    const [searchText, setSearchText] = useState('');
    const [filteredIngredients, setFilteredIngredients] = useState([]);
    const [ingredientAmounts, setIngredientAmounts] = useState({});
    const [totals, setTotals] = useState({ protein: 0, fat: 0, carbs: 0, kcals: 0 });
    const userInfo = getLocalStorageItem('limitless-user');
    const coachId = userInfo.user.coachID;
    const [uploadedImage, setUploadedImage] = useState(null); // For lagring av valgt bilde
    const [isLoading, setIsLoading] = useState(false);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setUploadedImage(file);
    };

    // Hent ingredienser og måltidsbilder fra Strapi
    useEffect(() => {
        async function fetchData() {
            try {
                const ingredientResponse = await axiosAuthFetch(`${API_BASE_URL}/ingredients?populate=*`);
                console.log(ingredientResponse);
                setIngredients(ingredientResponse.data.map(item => ({ ...item.attributes, id: item.id })));

            
                setMealImages(mealImageArray.map(item => ({ id: item.id, url: item.attributes.image.data.attributes.url })));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
        //eslint-disable-next-line
    }, []);

    const handleSearchChange = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchText(searchValue);
    
        if (searchValue === '') {
            // Hvis søkefeltet er tomt, vis alle ingrediensene igjen
            setFilteredIngredients([]);
        } else {
            // Filtrer ingrediensene basert på søket
            const filtered = ingredients.filter(ingredient =>
                ingredient.name.toLowerCase().includes(searchValue)
            );
            setFilteredIngredients(filtered);
        }
    };

    const handleIngredientChange = (ingredientId, amount) => {
        setIngredientAmounts(prev => ({
            ...prev,
            [ingredientId]: amount
        }));
    };

    const handleAddIngredient = (ingredientId, unit) => {
        const amount = parseFloat(ingredientAmounts[ingredientId]);
        const ingredient = ingredients.find(ing => ing.id === ingredientId);
        if (ingredient && amount) {
            setSelectedIngredients(prevIngredients => [
                ...prevIngredients,
                { ...ingredient, amount, unit }
            ]);
            setFilteredIngredients(prev => prev.filter(ing => ing.id !== ingredientId));
            calculateTotals([...selectedIngredients, { ...ingredient, amount }]);
        }
    };

    const handleRemoveIngredient = (ingredientId) => {
        const updatedIngredients = selectedIngredients.filter(ing => ing.id !== ingredientId);
        setSelectedIngredients(updatedIngredients);
        calculateTotals(updatedIngredients);
    };

    const calculateTotals = (ingredients) => {
        let totalProtein = 0;
        let totalFat = 0;
        let totalCarbs = 0;
        let totalKcals = 0;
    
        ingredients.forEach(ingredient => {
            const { macros } = ingredient;
            const amount = parseFloat(ingredient.amount);
            const unit = ingredient.unit;
            const { protein, fat, carbs, kcals } = macros[0] || {};
    
            if (unit === "g") {
                // Hvis enheten er gram, beregn basert på makroer per 100g
                totalProtein += (protein / 100) * amount;
                totalFat += (fat / 100) * amount;
                totalCarbs += (carbs / 100) * amount;
                totalKcals += (kcals / 100) * amount;
            } else if (unit === "stk") {
                // Hvis enheten er stk, beregn direkte per stk
                totalProtein += protein * amount;
                totalFat += fat * amount;
                totalCarbs += carbs * amount;
                totalKcals += kcals * amount;
            } else if (unit === "dl") {
                // Hvis enheten er dl, antar vi 1 dl ≈ 100g
                const gramsEquivalent = amount * 100;
                totalProtein += (protein / 100) * gramsEquivalent;
                totalFat += (fat / 100) * gramsEquivalent;
                totalCarbs += (carbs / 100) * gramsEquivalent;
                totalKcals += (kcals / 100) * gramsEquivalent;
            }
        });
    
        setTotals({
            protein: Math.round(totalProtein),
            fat: Math.round(totalFat),
            carbs: Math.round(totalCarbs),
            kcals: Math.round(totalKcals)
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Valider obligatoriske felter før vi fortsetter
        if (!name || !description || !prepTime || !cookingTime || !serving || !category) {
            alert('Vennligst fyll ut alle obligatoriske felter (navn, beskrivelse, kategori, forberedelsestid, koketid og porsjoner).');
            return;
        }
    
        try {
            setIsLoading(true);
            let mealImageInstanceId = null;
            
            if (uploadedImage) {
                // Trinn 1: Last opp bildet
                const formData = new FormData();
                formData.append('files', uploadedImage);
                console.log('hei 2');
    
                const uploadResponse = await axiosImageAuthFetch(`${API_BASE_URL}/upload/`, formData, 'POST');
                console.log('Upload Response:', uploadResponse);
    
                const uploadedImageId = uploadResponse[0].id; // ID til media-filen
    
                // Trinn 2: Opprett en ny `meal_images`-instans med bildet
                const mealImageInstanceData = {
                    image: uploadedImageId, // Knytt media-filen til `meal_images`-instansen
                };
    
                const mealImageInstanceResponse = await axiosAuthFetch(`${API_BASE_URL}/meal-images`, { data: mealImageInstanceData }, 'POST');
                mealImageInstanceId = mealImageInstanceResponse.data.id; // ID til den nye `meal_images`-instansen
    
            } else if (selectedImage) {
                // Hvis brukeren har valgt en eksisterende `meal_images`-instans
                mealImageInstanceId = selectedImage; // ID til `meal_images`-instansen
            }
            console.log('hei4');
            // Trinn 3: Opprett måltidet og knytt `meal_images`-instansen
            const mealData = {
                name,
                description,
                prep_time: prepTime,
                cooking_time: cookingTime,
                serving,
                category,
                coaches: [coachId],
                cooking_instructions: cookingInstructions,
                preparation,
                meal_images: mealImageInstanceId ? [mealImageInstanceId] : [],
                kcals: totals.kcals,
                proteins: totals.protein,
                carbs: totals.carbs,
                fats: totals.fat 
            };
    
            console.log(mealData);
            const mealResponse = await axiosAuthFetch(`${API_BASE_URL}/meals`, { data: mealData }, 'POST');
            console.log(mealResponse);
            const mealID = mealResponse.data.id;
    
            // Legg til ingredienser for måltidet
            await Promise.all(selectedIngredients.map(async (ingredient) => {
                await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients`, {
                    data: {
                        meals: [mealID],
                        ingredients: [ingredient.id],
                        amount: ingredient.amount,
                        unit: ingredient.unit,
                    },
                }, 'POST');
            }));
    
            alert('Måltid opprettet!');
            window.location.reload();
        } catch (error) {
            console.error('Error creating meal:', error);

                if (error.response) {
                    console.error('Server Error:', error.response.data);
                    alert(`Kunne ikke opprette måltid. Feil: ${JSON.stringify(error.response.data)}`);
                } else if (error.request) {
                    console.error('No response received:', error.request);
                    alert('Ingen respons fra serveren. Vennligst sjekk nettverkstilkoblingen din.');
                } else {
                    console.error('Error setting up request:', error.message);
                    alert(`Kunne ikke opprette måltid. Feil: ${error.message}`);
                }
        } finally {
            setIsLoading(false);
        }
    };



    if(isLoading) {
        return <Loader />
    }

    return (
        <div className={styles.container}>
            <h2>Opprett nytt måltid</h2>
            <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
                <input
                    className={styles.input}
                    type="text"
                    placeholder="Navn"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <textarea
                    className={styles.textArea}
                    placeholder="Beskrivelse"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <input
                    className={styles.input}
                    type="number"
                    placeholder="Forberedelsestid (minutter)"
                    value={prepTime}
                    onChange={(e) => setPrepTime(e.target.value)}
                />
                <input
                    className={styles.input}
                    type="number"
                    placeholder="Tid å lage (minutter)"
                    value={cookingTime}
                    onChange={(e) => setCookingTime(e.target.value)}
                />
                <input
                    className={styles.input}
                    type="number"
                    placeholder="Antall porsjoner"
                    value={serving}
                    onChange={(e) => setServing(e.target.value)}
                />
                <select
                    className={styles.select}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                >
                    <option value="">Velg kategori</option>
                    <option value="Frokost">Frokost</option>
                    <option value="Lunsj">Lunsj</option>
                    <option value="Middag">Middag</option>
                    <option value="Kveldsmat">Kveldsmat</option>
                    <option value="Mellommåltid">Mellommåltid</option>
                    <option value="Snack">Snack</option>
                </select>
                <textarea
                    className={styles.textArea}
                    placeholder="Instruksjoner"
                    value={cookingInstructions}
                    onChange={(e) => setCookingInstructions(e.target.value)}
                />
                <textarea
                    className={styles.textArea}
                    placeholder="Preparation"
                    value={preparation}
                    onChange={(e) => setPreparation(e.target.value)}
                />

                <h3>Valgte Ingredienser</h3>
                <ul className={styles.selectedIngredientList}>
                    {/* {selectedIngredients.map(ingredient => (
                        <li key={ingredient.name} className={styles.selectedIngredientItem}>
                            <strong>{ingredient.name}:</strong> {ingredient.amount} {ingredient.unit} */}
                            {/* {ingredient.macros[0]?.kcals || 0} kcal */}
                            {/* <button className={styles.removeButton} onClick={() => handleRemoveIngredient(ingredient.id)}>
                                Fjern
                            </button>
                        </li>
                    ))} */}
                    {selectedIngredients.map(ingredient => (
                        <li key={ingredient.name} className={styles.selectedIngredientItem}>
                            <div className={styles.ingredientRow}>
                                <div className={styles.ingredientName} title={ingredient.name}>
                                    <strong>{ingredient.name}</strong>
                                </div>
                                <div className={styles.ingredientAmount}>
                                    {ingredient.amount} {ingredient.unit}
                                </div>
                                <button
                                    className={styles.removeButton}
                                    onClick={() => handleRemoveIngredient(ingredient.id)}
                                >
                                    Fjern
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>

                <h4>Totalt Næringsinnhold</h4>
                
                <p>Protein: {totals.protein}g</p>
                <p>Fett: {totals.fat}g</p>
                <p>Karbohydrater: {totals.carbs}g</p>
                <p><strong>Kalorier</strong> {totals.kcals} kcal</p>

                <h3>Ingredienser</h3>
                <input
                    type="text"
                    placeholder="Søk etter ingrediens"
                    value={searchText}
                    onChange={handleSearchChange}
                    className={styles.input}
                />
                {filteredIngredients.map(ingredient => (
                    <div key={ingredient.id} className={styles.ingredientRow}>
                        <label className="col-5">{ingredient.name} ({ingredient.unit})</label>
                        <div className={styles.ingredientSub}>
                            <input
                                className={styles.input}
                                type="number"
                                placeholder={`Mengde (${ingredient.unit})`}
                                onChange={(e) => handleIngredientChange(ingredient.id, e.target.value, ingredient.unit)}
                            />
                            <button className={styles.addButton} onClick={() => handleAddIngredient(ingredient.id, ingredient.unit)}>
                                <strong>+</strong>
                            </button>
                        </div>
                    </div>
                ))}

                <h3>Velg et bilde</h3>
                <div className={styles.imageScrollContainer}>
                    {mealImages.map(image => (
                        <div 
                            key={image.id} 
                            className={`${styles.imageOption} ${selectedImage === image.id ? styles.selected : ''}`}
                            onClick={() => setSelectedImage(image.id)}
                        >
                            <img src={image.url} alt="Meal" className={`${styles.imageThumbnail} ${selectedImage === image.id ? styles.imageSelected : ''}`} />
                            {selectedImage === image.id && (
                                <div className={styles.overlay}>
                                    <span className={styles.checkmark}>✔</span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <h3>Last opp et eget bilde</h3>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                />
                <button 
                    className={styles.submitButton} 
                    onClick={handleSubmit}
                    >Lagre Måltid</button>
            </form>
        </div>
    );
}


