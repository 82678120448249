import React from 'react';
import CreateIngredient from '../../global/forms/createIngredient';

export default function IngredientsPage() {

    
    return(
        <div className="">
            <CreateIngredient/>
        </div>
    )
}