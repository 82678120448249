import React from 'react';
import styles from './styles.module.css';
import MealPageLayoutCoachSide from '../../layouts/meals/coachSide';

export default function MealsPage(){
    
    return(
        <div className={styles.container}>
            <MealPageLayoutCoachSide />
        </div>
    )
}