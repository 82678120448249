// // AddMealModal.jsx
// import React, { useState, useEffect } from 'react';
// import { axiosAuthFetch } from '../../../../helpers/axios';
// import { API_GRAPHQL } from '../../../../constants';
// import styles from './styles.module.scss'; // Lag din egen CSS-modul

// export default function AddMealModal({ coachId, onClose, onAddMeal }) {
//   const [isLoading, setIsLoading] = useState(false);
//   const [mealsData, setMealsData] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const CATEGORIES = ["Frokost", "Lunsj", "Middag", "Snack", "Mellommåltid", "Kveldsmat"];

//   useEffect(() => {
//     async function fetchMeals() {
//       setIsLoading(true);
//       try {
//         const query = `
//           query {
//             meals(pagination: { limit: 1000 }, filters: { coaches : { id: { eq: "${coachId}"}}}) {
//               data {
//                 id
//                 attributes {
//                   name
//                   category
//                   kcals
//                   proteins
//                   fats
//                   carbs
//                   meal_images {
//                     data {
//                       attributes {
//                         image {
//                           data {
//                             attributes {
//                               url
//                             }
//                           }
//                         }
//                       }
//                     }
//                   }
//                   meal_instances {
//                     data {
//                       id
//                       attributes {
//                         variant_name
//                         kcals
//                         proteins
//                         fats
//                         carbs
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         `;
//         const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'POST');
//         setMealsData(response.data.meals.data);
//       } catch (error) {
//         console.error("Error fetching meals in modal:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//     fetchMeals();
//   }, [coachId]);

//   // Filtrér meals etter søketerm
//   const filteredMeals = mealsData.filter(meal =>
//     meal.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   // Gruppér meals etter kategori
//   const groupedMeals = CATEGORIES.reduce((acc, category) => {
//     acc[category] = filteredMeals.filter(meal => meal.attributes.category === category);
//     return acc;
//   }, {});

//   // Local state for valg
//   const [selectedMealId, setSelectedMealId] = useState(null);
//   const [selectedInstanceId, setSelectedInstanceId] = useState(null);

//   const handleMealSelect = (mealId) => {
//     setSelectedMealId(mealId);
//     setSelectedInstanceId(null); // Tilbakestill variant-valg når nytt måltid velges
//   };

//   const handleInstanceSelect = (instanceId) => {
//     setSelectedInstanceId(instanceId);
//   };

//   // const handleAdd = () => {
//   //   if (!selectedMealId) {
//   //     alert("Velg et måltid!");
//   //     return;
//   //   }
//   //   // Callback: send med mealId og instanceId (kan være null)
//   //   onAddMeal(selectedMealId, selectedInstanceId);
//   //   onClose();
//   // };

//   const handleAdd = () => {
//     if (!selectedMealId) {
//       alert("Velg et måltid!");
//       return;
//     }
    
//     // Finn det valgte måltidsobjektet fra groupedMeals
//     let selectedMealObject = null;
//     Object.keys(groupedMeals).forEach(category => {
//       if (!selectedMealObject) {
//         const meal = groupedMeals[category].find(m => m.id === selectedMealId);
//         if (meal) {
//           selectedMealObject = meal;
//         }
//       }
//     });
    
//     onAddMeal(selectedMealId, selectedInstanceId, selectedMealObject);
//     onClose();
//   };
  

//   return (
//     <div className={styles.modalContainer}>
//       <div className={styles.modalHeader}>
//         <h2>Legg til måltid</h2>
//         <button onClick={onClose}>X</button>
//       </div>
//       <input 
//         type="text"
//         placeholder="Søk etter måltid..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className={styles.searchInput}
//       />
//       {isLoading ? (
//         <div>Laster måltider...</div>
//       ) : (
//         Object.keys(groupedMeals).map(category => (
//           groupedMeals[category].length > 0 && (
//             <div key={category} className={styles.categorySection}>
//               <h3>{category}</h3>
//               <div className={styles.mealList}>
//                 {groupedMeals[category].map(meal => (
//                   <div
//                     key={meal.id}
//                     className={`${styles.mealItem} ${selectedMealId === meal.id ? styles.selectedMeal : ''}`}
//                     onClick={() => handleMealSelect(meal.id)}
//                   >
//                     {meal.attributes.name}
//                   </div>
//                 ))}
//               </div>
//               {/* Om det er et måltid valgt i denne kategorien, vis varianter (instances) */}
//               {selectedMealId &&
//                 groupedMeals[category].some(meal => meal.id === selectedMealId) &&
//                 (() => {
//                   const selectedMeal = groupedMeals[category].find(meal => meal.id === selectedMealId);
//                   const instances = selectedMeal.attributes.meal_instances?.data || [];
//                   return instances.length > 0 ? (
//                     <div className={styles.instanceSection}>
//                       <h4>Velg variant (om tilgjengelig)</h4>
//                       <div className={styles.instanceList}>
//                         {instances.map(inst => (
//                           <div
//                             key={inst.id}
//                             className={`${styles.instanceItem} ${selectedInstanceId === inst.id ? styles.selectedInstance : ''}`}
//                             onClick={() => handleInstanceSelect(inst.id)}
//                           >
//                             {inst.attributes.variant_name || "Standard"}
//                           </div>
//                         ))}
//                       </div>
//                     </div>
//                   ) : null;
//                 })()}
//             </div>
//           )
//         ))
//       )}
//       <div className={styles.modalFooter}>
//         <button onClick={handleAdd} className={styles.addButton}>Legg til</button>
//       </div>
//     </div>
//   );
// }


import React, { useState, useEffect } from 'react';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_GRAPHQL } from '../../../../constants';
import styles from './styles.module.scss'; // Sørg for at denne filen inneholder dine stilklasser
import CheckmarkIcon from '../../checkmarkIcon';

export default function AddMealModal({ coachId, onClose, onAddMeal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [mealsData, setMealsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  // Bruk samme kategorier som i CreateMealPlanForm
  const CATEGORIES = ["Frokost", "Lunsj", "Middag", "Snack", "Mellommåltid", "Kveldsmat"];

  useEffect(() => {
    async function fetchMeals() {
      setIsLoading(true);
      try {
        const query = `
          query {
            meals(pagination: { limit: 1000 }, filters: { coaches: { id: { eq: "${coachId}" } } }) {
              data {
                id
                attributes {
                  name
                  category
                  kcals
                  proteins
                  fats
                  carbs
                  meal_images {
                    data {
                      attributes {
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  meal_instances {
                    data {
                      id
                      attributes {
                        variant_name
                        kcals
                        proteins
                        fats
                        carbs
                      }
                    }
                  }
                }
              }
            }
          }
        `;
        const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'POST');
        setMealsData(response.data.meals.data);
      } catch (error) {
        console.error("Error fetching meals in modal:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchMeals();
  }, [coachId]);

  // Filtrer måltider etter søketerm
  const filteredMeals = mealsData.filter(meal =>
    meal.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Gruppér måltider etter kategori
  const groupedMeals = CATEGORIES.reduce((acc, category) => {
    acc[category] = filteredMeals.filter(meal => meal.attributes.category === category);
    return acc;
  }, {});

  // Local state for valgt måltid og variant
  const [selectedMealId, setSelectedMealId] = useState(null);
  const [selectedInstanceId, setSelectedInstanceId] = useState(null);

  const handleMealSelect = (mealId) => {
    setSelectedMealId(mealId);
    setSelectedInstanceId(null); // Tilbakestill variant-valg når et nytt måltid velges
  };

  const handleInstanceSelect = (instanceId) => {
    setSelectedInstanceId(instanceId);
  };

  // Når brukeren trykker "Legg til" – finn det valgte måltidet og send det med callbacken
  const handleAdd = () => {
    if (!selectedMealId) {
      alert("Velg et måltid!");
      return;
    }
    // Finn det valgte måltidsobjektet ved å lete gjennom de grupperte måltidene
    let selectedMealObject = null;
    Object.keys(groupedMeals).forEach(category => {
      if (!selectedMealObject) {
        const meal = groupedMeals[category].find(m => m.id === selectedMealId);
        if (meal) {
          selectedMealObject = meal;
        }
      }
    });
    
    // Callback: send med mealId, eventuell instanceId og hele måltidsobjektet
    onAddMeal(selectedMealId, selectedInstanceId, selectedMealObject);
    onClose();
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalHeader}>
        <h2 className="text-center">Legg til måltid</h2>
      </div>
      <div className="col-12 d-flex flex-column align-items-center">
        <input 
          type="text"
          placeholder="Søk etter måltid..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={`${styles.input} col-6`}
        />
      </div>
  
      {isLoading ? (
        <div>Laster måltider...</div>
      ) : (
        Object.keys(groupedMeals).map(category => (
          groupedMeals[category].length > 0 && (
            <div key={category} className={styles.categorySection}>
              <h2 className={styles.categoryTitle}>{category}</h2>
              <div className={styles.horizontalScrollContainer}>
                {groupedMeals[category].map(meal => (
                  <div key={meal.id} onClick={() => handleMealSelect(meal.id)}>
                    <div 
                      className={`${styles.mealCard} ${selectedMealId === meal.id ? styles.selectedMeal : ""}`}
                      style={{
                        backgroundImage: `url(${meal.attributes.meal_images?.data?.[0]?.attributes?.image?.data?.attributes?.url || 'fallback.jpg'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    >
                      {selectedMealId === meal.id && (
                        <div className={styles.overlayCheck}>
                          <span className={styles.checkmark}><CheckmarkIcon/></span>
                        </div>
                      )}
                    </div>
                    <h4 className="text-center mt-2">
                      {meal.attributes.name} – {meal.attributes.kcals} kcal
                    </h4>
                    {selectedMealId === meal.id && meal.attributes.meal_instances?.data?.length > 0 && (
                      <div className={styles.instanceList}>
                        <strong>Varianter:</strong>
                        {meal.attributes.meal_instances.data.map(inst => (
                          <div
                            key={inst.id}
                            className={`${styles.instanceItem} ${selectedInstanceId === inst.id ? styles.selectedInstance : ""}`}
                            onClick={(e) => {
                              e.stopPropagation(); 
                              handleInstanceSelect(inst.id);
                            }}
                          >
                            <span>{inst.attributes.variant_name || "Standard"}</span>
                            {selectedInstanceId === inst.id && (
                              <span className={styles.instanceCheckmark}>✔</span>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )
        ))
      )}
      <div className={styles.modalFooter}>
        <div onClick={handleAdd} className={styles.submitButton}>Legg til</div>
      </div>
    </div>
  );
}
