import { axiosAuthFetch } from "../../helpers/axios";
import { API_GRAPHQL } from "../../constants";

export async function GetNotifications(id) {
    const query =`
        query{
            notifications(
                filters: {
                    receiver: {id: {eq:${id}}}
                    isRead: {eq: false}
                }
            ) {
                data{
                    id
                    attributes{
                        message
                        event
                        workout_session{
                            data{
                                id
                            }
                        }
                        check_in{
                            data{
                                id
                            }
                        }
                    }
                }    
            }
        }
    `;

    const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'post');

    return response.data;
}