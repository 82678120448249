import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { GetSpecificMealPlan } from '../../../hooks/newHooks/coachSide/mealPlans';
import { axiosAuthFetch } from '../../../helpers/axios';
import { API_BASE_URL } from '../../../constants';
import { getLocalStorageItem } from '../../global/storage';
import ResponsiveModal from '../../global/responsiveModal';
import AddMealModal from '../../global/forms/addMealModal';
import styles from './styles.module.scss';  // Bruk din egen SCSS-fil
import { getClients } from '../../../hooks/newHooks/coachSide/clientInfo';
import CheckmarkIcon from '../../global/checkmarkIcon';

export default function SpecificMealPlanPage() {
  const { mealplanId } = useParams();
  const userInfo = getLocalStorageItem('limitless-user');
  const user = userInfo.user;
  const coachId = user.coachID;
  const navigate = useNavigate();

  // Hovedstate
  const [isLoading, setIsLoading] = useState(false);
  const [mealPlan, setMealPlan] = useState(null);
  // editedPlan: data som skal sendes til backend; meals og meal_instances som arrays med string-ID-er
  const [editedPlan, setEditedPlan] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  // Flat liste over meals (kombinerte hovedmåltider og instanser)
  const [allMeals, setAllMeals] = useState([]);

  // Klienter (utelatt noe av klientlogikken her)
  const [clients, setClients] = useState([]);
  const [coachClients, setCoachClients] = useState([]);
  //const [showClientModal, setShowClientModal] = useState(false);

  // Redigeringsmodus
  const [editing, setEditing] = useState(false);

  // Definer kategorier – inkludert fallback "Uten kategori"
  //eslint-disable-next-line
  const CATEGORIES = ["Frokost", "Lunsj", "Middag", "Snack", "Mellommåltid", "Kveldsmat", "Uten kategori"];

  // Hent matplanen ved mount
  useEffect(() => {
    async function getMealPlan() {
      setIsLoading(true);
      try {
        const response = await GetSpecificMealPlan(mealplanId);
        const planData = response.data.mealPlan.data;
        const attributes = planData.attributes;
        // Transformer slik at meals og meal_instances blir arrays med string-ID-er
        const transformedPlan = {
          ...attributes,
          meals: attributes.meals?.data?.map(meal => String(meal.id)) || [],
          meal_instances: attributes.meal_instances?.data?.map(inst => String(inst.id)) || [],
          clients: attributes.clients?.data?.map(client => String(client.id)) || []
        };
        setMealPlan(planData);
        setEditedPlan(transformedPlan);
        setClients(attributes.clients?.data || []);
        setAllMeals(combineMeals(attributes));
      } catch (error) {
        console.error('Error fetching meal plan:', error);
      } finally {
        setIsLoading(false);
      }
    }
    getMealPlan();
  }, [mealplanId]);

  // (Valgfritt) Pre-populer editedPlan.meals med det første hovedmåltidet i hver kategori dersom den er tom
  useEffect(() => {
    if ((!editedPlan.meals || editedPlan.meals.length === 0) && allMeals.length > 0) {
      const defaultMealIds = [];
      CATEGORIES.forEach(category => {
        const meal = allMeals.find(m => m.category === category && m.type === 'meal');
        if (meal) {
          defaultMealIds.push(meal.id);
        }
      });
      setEditedPlan(prev => ({ ...prev, meals: defaultMealIds }));
    }
  }, [allMeals, editedPlan.meals, CATEGORIES]);

  useEffect(() => {
    const fetchCoachClients = async () => {
        try {
          const response = await getClients(coachId);
          console.log('CLIENTS', response);
          setCoachClients(response.coach.data.attributes.clients.data);
        } catch (error) {
          console.error("Error fetching coach clients:", error);
        } finally {
          setIsLoading(false);
        }
      };
    fetchCoachClients();
  },[coachId]);

  

  // For makroberegning: union av editedPlan.meals og editedPlan.meal_instances
  const selectedMealIds = useMemo(() => {
    return [...(editedPlan.meals || []), ...(editedPlan.meal_instances || [])];
  }, [editedPlan.meals, editedPlan.meal_instances]);

  const planMacros = useMemo(() => {
    return computePlanMacros(allMeals, selectedMealIds, CATEGORIES);
  }, [allMeals, selectedMealIds, CATEGORIES]);

  // Kombiner meals og instanser til en flat struktur, med et ekstra felt 'imageUrl'
  function combineMeals(attributes) {
    const mealArray = attributes.meals?.data || [];
    const instanceArray = attributes.meal_instances?.data || [];
    const combined = [];
  
    // Hovedmåltider
    mealArray.forEach(item => {
      const imageUrl = item.attributes.meal_images?.data?.[0]?.attributes?.image?.data?.attributes?.url || 'fallback.jpg';
      combined.push({
        id: String(item.id),
        name: item.attributes.name,
        kcals: item.attributes.kcals,
        protein: item.attributes.proteins,
        fat: item.attributes.fats,
        carbs: item.attributes.carbs,
        type: 'meal',
        category: item.attributes.category || "Uten kategori",
        baseMealId: String(item.id),
        imageUrl,
        attributes: item.attributes
      });
    });
  
    // Instanser (varianter)
    instanceArray.forEach(item => {
      const linkedMeal = item.attributes.meal?.data;
      const linkedMealName = linkedMeal?.attributes?.name || '';
      // Hent bildet fra linkedMeal
      const imageUrl = linkedMeal?.attributes?.meal_images?.data?.[0]?.attributes?.image?.data?.attributes?.url || 'fallback.jpg';
      combined.push({
        id: String(item.id),
        name: `${item.attributes.variant_name} av ${linkedMealName}`,
        kcals: item.attributes.kcals,
        protein: item.attributes.proteins,
        fat: item.attributes.fats,
        carbs: item.attributes.carbs,
        type: 'instance',
        category: linkedMeal?.attributes?.category || "Uten kategori",
        baseMealId: linkedMeal ? String(linkedMeal.id) : null,
        imageUrl,
        attributes: item.attributes
      });
    });
  
    return combined;
  }

  // computePlanMacros: For hver kategori, finn først elementet blant de valgte (basert på unionen)
  // Hvis ingen er valgt for en kategori, bruk det første elementet fra allMeals for den kategorien.
  function computePlanMacros(allMeals, selectedMealIds, categories) {
    let totalProtein = 0;
    let totalCarbs = 0;
    let totalFat = 0;
    let totalKcals = 0;
    
    categories.forEach(category => {
      let mealsInCategory = allMeals.filter(meal =>
        selectedMealIds.includes(meal.id) && meal.category === category
      );
      if (mealsInCategory.length === 0) {
        mealsInCategory = allMeals.filter(meal => meal.category === category);
      }
      if (mealsInCategory.length > 0) {
        const meal = mealsInCategory[0];
        totalProtein += Number(meal.protein) || 0;
        totalCarbs += Number(meal.carbs) || 0;
        totalFat += Number(meal.fat) || 0;
        totalKcals += Number(meal.kcals) || 0;
      }
    });
    
    return { totalProtein, totalCarbs, totalFat, totalKcals };
  }

  function handleFieldChange(e) {
    const { name, value } = e.target;
    setEditedPlan(prev => ({ ...prev, [name]: value }));
  }

  async function handleSaveChanges() {
    try {
      setIsLoading(true);
      const response = await axiosAuthFetch(
        `${API_BASE_URL}/meal-plans/${mealPlan.id}`,
        { data: editedPlan },
        'PUT'
      );
      console.log("Plan updated:", response);
      setMealPlan(prev => ({
        ...prev,
        attributes: editedPlan
      }));
      setEditing(false);
    } catch (error) {
      console.error("Error updating plan:", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function disconnectMealOrInstance(id, type) {
    try {
      setIsLoading(true);
      let payload = {};
      if (type === 'meal') {
        payload = { data: { meals: { disconnect: [id] } } };
      } else if (type === 'instance') {
        payload = { data: { meal_instances: { disconnect: [id] } } };
      }
      const response = await axiosAuthFetch(
        `${API_BASE_URL}/meal-plans/${mealplanId}`,
        payload,
        'PUT'
      );
      console.log('Disconnected successfully:', response);
  
      setEditedPlan(prev => ({
        ...prev,
        meals: type === 'meal'
          ? prev.meals.filter(mealId => mealId !== id)
          : prev.meals,
        meal_instances: type === 'instance'
          ? prev.meal_instances.filter(instId => instId !== id)
          : prev.meal_instances,
      }));
      setAllMeals(prev => prev.filter(meal => meal.id !== id));
    } catch (error) {
      console.error("Error disconnecting meal/instance:", error);
    } finally {
      setIsLoading(false);
    }
  }

  // Callback fra AddMealModal – hindrer duplikat for samme basismåltid:
  const handleAddMealFromModal = (mealId, instanceId, mealDetails) => {
    const baseMealId = String(mealDetails.id);
    // Sjekk om et element for dette basismåltidet allerede er lagt til
    const alreadyAdded = (editedPlan.meals && editedPlan.meals.includes(baseMealId)) ||
                         (editedPlan.meal_instances && editedPlan.meal_instances.some(instId => {
                           const variant = allMeals.find(m => m.id === instId && m.type === 'instance');
                           return variant && variant.baseMealId === baseMealId;
                         }));
    if (alreadyAdded) {
      alert("Du kan kun legge til én variant (eller hovedmåltid) for dette måltidet.");
      return;
    }
    if (instanceId) {
      // Variant valgt: legg kun til variant
      setEditedPlan(prev => ({
        ...prev,
        meal_instances: prev.meal_instances ? [...prev.meal_instances, String(instanceId)] : [String(instanceId)]
      }));
      setAllMeals(prev => {
        if (prev.find(m => m.id === String(instanceId) && m.type === 'instance')) {
          return prev;
        }
        const instanceData = mealDetails.attributes.meal_instances?.data?.find(inst => String(inst.id) === String(instanceId));
        let displayName = mealDetails.attributes.name;
        if (instanceData) {
          displayName = `${instanceData.attributes.variant_name} av ${mealDetails.attributes.name}`;
        }
        const newVariant = {
          id: String(instanceId),
          name: displayName,
          kcals: instanceData ? instanceData.attributes.kcals : mealDetails.attributes.kcals,
          protein: instanceData ? instanceData.attributes.proteins : mealDetails.attributes.proteins,
          fat: instanceData ? instanceData.attributes.fats : mealDetails.attributes.fats,
          carbs: instanceData ? instanceData.attributes.carbs : mealDetails.attributes.carbs,
          type: 'instance',
          baseMealId: baseMealId,
          category: mealDetails.attributes.category || "Uten kategori",
          attributes: mealDetails.attributes
        };
        return [...prev, newVariant];
      });
    } else {
      // Hovedmåltid: legg til bare hovedmåltid
      setEditedPlan(prev => ({
        ...prev,
        meals: prev.meals ? [...prev.meals, baseMealId] : [baseMealId]
      }));
      setAllMeals(prev => {
        if (prev.find(m => m.id === baseMealId && m.type === 'meal')) {
          return prev;
        }
        const newMeal = {
          id: baseMealId,
          name: mealDetails.attributes.name,
          kcals: mealDetails.attributes.kcals,
          protein: mealDetails.attributes.proteins,
          fat: mealDetails.attributes.fats,
          carbs: mealDetails.attributes.carbs,
          type: 'meal',
          baseMealId: baseMealId,
          category: mealDetails.attributes.category || "Uten kategori",
          attributes: mealDetails.attributes
        };
        return [...prev, newMeal];
      });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  async function handleCopyPlan() {
    try {
      setIsLoading(true);
      const copiedPlan = {
        ...editedPlan,
        name: editedPlan.name + ' - Kopi',
        coach: coachId,
        meals: mealPlan.attributes.meals.data.map(meal => String(meal.id)),
        meal_instances: mealPlan.attributes.meal_instances.data.map(instance => String(instance.id)),
        clients: []
      };
      const response = await axiosAuthFetch(
        `${API_BASE_URL}/meal-plans`,
        { data: copiedPlan },
        'POST'
      );
      console.log("Plan copied successfully:", response);
      alert('Plan kopiert!');
      navigate(-1);
    } catch (error) {
      console.error("Error copying plan:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function handleClientSelect(e) {
    const selectedId = e.target.value;
    if (!selectedId) return;
    // Finn klientobjektet fra coachClients
    const client = coachClients.find(c => c.id === selectedId);
    if (client) {
      // Sjekk om klienten allerede har en tilknyttet matplan
      const existingMealPlans = client.attributes.meal_plans?.data || [];
      if (existingMealPlans.length > 0) {
        const confirmation = window.confirm(
          "En klient kan kun ha en matplan - hvis du velger å tildele klienten denne planen blir den andre matplanen fjernet - fortsette?"
        );
        if (!confirmation) {
          // Avbryt operasjonen hvis brukeren ikke bekrefter
          return;
        }
        // Hvis det finnes en eksisterende plan, koble fra den første (eller den eneste) matplanen
        const oldMealPlanId = existingMealPlans[0].id;
        disconnectClientFromMealPlan(client.id, oldMealPlanId);
      }
      // Sjekk om klienten allerede er lagt til i den nye planen
      if (editedPlan.clients && editedPlan.clients.includes(selectedId)) {
        alert("Denne klienten er allerede lagt til.");
        return;
      }
      // Legg til klienten i editedPlan (ny matplan)
      setEditedPlan(prev => ({
        ...prev,
        clients: prev.clients ? [...prev.clients, selectedId] : [selectedId]
      }));
      // Oppdater visningen (clients-state) slik at den nye klienten vises
      setClients(prev => {
        if (prev.find(c => c.id === selectedId)) return prev;
        return [...prev, client];
      });
    }
  }

  async function disconnectClientFromMealPlan(clientId, mealPlanId) {
    try {
      const payload = {
        data: {
          clients: {
            disconnect: [clientId]
          }
        }
      };
      const response = await axiosAuthFetch(
        `${API_BASE_URL}/meal-plans/${mealPlanId}`,
        payload,
        'PUT'
      );
      console.log(`Client ${clientId} disconnected from meal plan ${mealPlanId}:`, response);
    } catch (error) {
      console.error("Error disconnecting client from meal plan:", error);
    }
  }

  async function deletePlan(id) {
    const confirmed = window.confirm("Er du sikker på at du vil slette planen?");
    if (!confirmed) return;
    
    try{
        setIsLoading(true);
        const response = await axiosAuthFetch(`${API_BASE_URL}/meal-plans/${mealplanId}`,{},'delete');
        console.log(response);
        alert('Planen er slettet!');
        navigate(-1);
    } catch(error) {
        console.error(error);
    } finally {
        setIsLoading(false);
    }
  }

  async function disconnectClient(clientId) {
    try {
      setIsLoading(true);
      const payload = {
        data: {
          clients: {
            disconnect: [clientId]
          }
        }
      };
      const response = await axiosAuthFetch(
        `${API_BASE_URL}/meal-plans/${mealplanId}`,
        payload,
        'PUT'
      );
      console.log("Client disconnected:", response);
      setEditedPlan(prev => ({
        ...prev,
        clients: prev.clients ? prev.clients.filter(id => id !== clientId) : []
      }));
      setClients(prev => prev.filter(client => client.id !== clientId));
    } catch (error) {
      console.error("Error disconnecting client:", error);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading || !mealPlan || !mealPlan.attributes) {
    return <div>Laster inn matplan...</div>;
  }

  return (
    <div className={`${styles.wrapper} ${styles.form}  specific-meal-plan-page`} style={{ padding: '20px' }}>
      <div className={`${styles.overskrift} text-center`}>{mealPlan.attributes.name}</div>
      {editing ? (
        <div className="edit-fields d-flex flex-column align-items-center">
          <div className="d-flex flex-column align-items-center">
            <label>Navn:</label>
            <input className={`${styles.input} rounded-3`} type="text" name="name" value={editedPlan.name} onChange={handleFieldChange} />
          </div>
          <div className="d-flex flex-column align-items-center">
            <label>Beskrivelse:</label>
            <textarea className={`${styles.input} rounded-3`} name="description" value={editedPlan.description} onChange={handleFieldChange} />
          </div>
          <div className="d-flex flex-column align-items-center">
            <label>Varighet:</label>
            <input className={`${styles.input} rounded-3`} type="text" name="duration" value={editedPlan.duration} onChange={handleFieldChange} />
          </div>
          <div className={`${styles.addButton}`} onClick={handleSaveChanges}>Lagre endringer</div>
          <div className={`${styles.removeButton}`} onClick={() => setEditing(false)}>Avbryt</div>
        </div>
      ) : (
        <div className="plan-details d-flex flex-column align-items-center">
          <div>{mealPlan.attributes.description}</div>
          <div>Varighet: {mealPlan.attributes.duration}</div>
          <div>Mål: {mealPlan.attributes.goal}</div>
          <div className="macroOverview d-flex flex-column align-items-center">
            <div className={`${styles.makroH}`}>Daglig Makrooversikt (basert på første valg per kategori)</div>
            <div>Protein: {planMacros.totalProtein} g</div>
            <div>Karbohydrater: {planMacros.totalCarbs} g</div>
            <div>Fett: {planMacros.totalFat} g</div>
            <div>Kalorier: {planMacros.totalKcals} kcal</div>
          </div>
          <div className={`${styles.editButton}`} onClick={() => setEditing(true)}>Rediger plan</div>
        </div>
      )}

      {/* Gruppér visningen av måltider etter kategori */}
      <h2 className="text-center">Måltider i planen</h2>
      {CATEGORIES.map(cat => {
        const mealsInCategory = allMeals.filter(meal => meal.category === cat);
        if (mealsInCategory.length === 0) return null;
        return (
          <div key={cat} className={styles.categorySection}>
            <h2 className={`${styles.categoryTitle} text-center`}>{cat}</h2>
            <div className={styles.horizontalScrollContainer}>
              {mealsInCategory.map(meal => (
                <div key={meal.id} onClick={() => { /* evt. velg for redigering */ }}>
                  <div
                    className={`${styles.mealCard} ${
                      (editedPlan.meals.includes(meal.id) ||
                        editedPlan.meal_instances.includes(meal.id))
                        ? styles.selected
                        : ""
                    }`}
                    style={{
                      backgroundImage: `url(${meal.imageUrl || 'fallback.jpg'})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  >
                    {(editedPlan.meals.includes(meal.id) ||
                      editedPlan.meal_instances.includes(meal.id)) && (
                      <div className={styles.overlayCheck}>
                        <span className={styles.checkmark}><CheckmarkIcon/></span>
                      </div>
                    )}
                  </div>
                  <h4 className="text-center mt-2">
                    {meal.name} – {meal.kcals} kcal
                  </h4>
                  {editing && (
                    <div className={`${styles.removeButton} m-auto`} onClick={() => disconnectMealOrInstance(meal.id, meal.type)}>Fjern</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      })}

      {editing && (
        <div className={`${styles.addButton} m-auto mb-4`} onClick={handleOpenModal}>Legg til måltid</div>
      )}

        <h2>Klienter med denne planen</h2>
        <div className={styles.clientsContainer}>
            {clients.map(client => (
            <div key={client.id} className={`${styles.clientCardWrapper} d-flex flex-column align-items-center`}>
                <ClientCard client={client} />
                {editing && (
                <div className={`${styles.removeButton} col-12`} onClick={() => disconnectClient(client.id)}>
                    Fjern
                </div>
                )}
            </div>
            ))}
        </div>
        {editing && (
            <div className={styles.clientSelectContainer}>
            <select className="col-12 rounded-5 p-2" onChange={handleClientSelect} defaultValue="">
                <option value="">Velg klient...</option>
                {coachClients
                .filter(c => !editedPlan.clients || !editedPlan.clients.includes(c.id))
                .map(client => (
                    <option key={client.id} value={client.id}>
                    {client.attributes.username}
                    </option>
                ))
                }
            </select>
         </div>
         )}
         {editing && (
            <div className="d-flex flex-column align-items-center">
                <div className={`${styles.addButton}`} onClick={handleSaveChanges}>Lagre endringer</div>
                <div className={`${styles.removeButton}`} onClick={() => setEditing(false)}>Avbryt</div>
            </div>
         )}

      <div className={`${styles.editButton} m-auto mt-4`} onClick={handleCopyPlan}>Kopier plan</div>
      <div className={`${styles.removeButton} m-auto mt-4`} onClick={deletePlan}>Slett plan</div>

      <ResponsiveModal isOpen={modalOpen} onClose={handleModalClose}>
        <AddMealModal 
          coachId={coachId}
          onClose={handleModalClose}
          onAddMeal={handleAddMealFromModal}
        />
      </ResponsiveModal>
    </div>
  );
}



export function ClientCard({ client }) {

  const username = client.attributes.username || '';
  const initials = username.substring(0, 2).toUpperCase();

  return (
    <div key={client.id} className={`${styles.clientCard} shadow rounded-3 p-3`}>
      <div className={styles.avatar}>
        {initials}
      </div>
      <div className={styles.clientName}>
        {username}
      </div>
    </div>
  );
}