import React, { useState, useEffect } from 'react';
import { ReactComponent as BackIcon } from '../../../images/icons/back.svg';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';

export default function BackButton() {

    const navigate = useNavigate();
    const [canGoBack, setCanGoBack] = useState(false);

    useEffect(() => {
        // Sjekk om det finnes minst 2 elementer i history (det vil si at vi har en side å gå tilbake til)
        if (window.history.length >= 2) {
          setCanGoBack(true);
        }
      }, []);
    
      if (!canGoBack) {
        // Returner ingenting hvis vi ikke har nok history
        return null;
      }
    
      const goBack = () => {
        navigate(-1);
      };

    return(
        <div className={`d-flex align-items-center justify-content-center col-12 ${styles.container}`} onClick={goBack}>
            <div className="col-3 col-md-1">
                <BackIcon />
            </div>
            <div className="p-0 my-0 ms-2 ms-md-3">
                Tilbake
            </div>
        </div>
    )
}