import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import Clock from '../../../../../images/icons/timer.png';
import Chef from '../../../../../images/icons/chef-hat.png';
import Serving from '../../../../../images/icons/user (2).png';
import IngredientIcon from '../../../../../images/icons/harvest.png';
import Image from 'react-bootstrap/Image';
import { axiosAuthFetch } from '../../../../../helpers/axios';
import { API_BASE_URL } from '../../../../../constants';
import Loader from '../../../../global/loader';
import ClientsList from '../clientList';
import VariantsList from '../variants';
//import { ReactComponent as BackIcon } from '../../../../../images/icons/back.svg';
import { useParams } from 'react-router';
import { GetSingleMealInfo } from '../../../../../hooks/specificMealPage';
import useIsDesktop from '../../../../../hooks/useIsDesktop';
import BackButton from '../../../../global/backButton';

export default function CoachMealDetail({  onBack, clients, }) {

  

    const isDesktop = useIsDesktop();
    const { mealId } = useParams();
    const [mealData, setMealData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [mealName, setMealName] = useState("");
    const [mealDescription, setMealDescription] = useState("");
    const [mealPrepTime, setMealPrepTime] = useState(0);
    const [mealCookingTime, setMealCookingTime] = useState(0);
    const [mealServing, setMealServing] = useState(1);
    const [mealPreparation, setMealPreparation] = useState("");
    const [mealCookingInstructions, setMealCookingInstructions] = useState("");
    const [mealCategory, setMealCategory] = useState("");
    const [mealIngredients, setMealIngredients] = useState([]);
    const [ingredientsToRemove, setIngredientsToRemove] = useState([]);
    const [ingredientSearch, setIngredientSearch] = useState('');
    const [filteredIngredients, setFilteredIngredients] = useState([]);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [newIngredientAmount, setNewIngredientAmount] = useState('');
    const [newIngredientUnit, setNewIngredientUnit] = useState('g');
    const [activeTab, setActiveTab] = useState("description");
    const [isEditing, setIsEditing] = useState(false);
    const [allIngredients, setAllIngredients] = useState([]);
    
        useEffect(() => {
            async function getMeals() {
                setIsLoading(true);
                try{
                    const mealData = await GetSingleMealInfo(mealId);
                    setMealData(mealData);
                    const ingredientResponse = await axiosAuthFetch(`${API_BASE_URL}/ingredients?populate=*`);
                    console.log(ingredientResponse);
                    //setIngredients(ingredientResponse.data.map(item => ({ ...item.attributes, id: item.id })));
                    setAllIngredients(ingredientResponse.data);
                } catch(error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            }
            getMeals();
            
        },[mealId]);

        useEffect(() => {
          if (mealData?.attributes) {
            const attrs = mealData.attributes;
            setMealName(attrs.name || "");
            setMealDescription(attrs.description || "");
            setMealPrepTime(attrs.prep_time || 0);
            setMealCookingTime(attrs.cooking_time || 0);
            setMealServing(attrs.serving || 1);
            setMealPreparation(attrs.preparation || "");
            setMealCookingInstructions(attrs.cooking_instructions || "");
            setMealCategory(attrs.category || "");
            setMealIngredients(attrs.meal_ingredients?.data || []);
          }
        }, [mealData]);
        
    //const mealAttributes = mealData.attributes;
    // const {
    //     name, prep_time, cooking_time, serving, meal_images, category, preparation, description, cooking_instructions,
    // } = mealAttributes;

    const imageUrl = mealData?.attributes?.meal_images?.data[0]?.attributes?.image?.data?.attributes?.url;
    // const [activeTab, setActiveTab] = useState("description");
    // const [isEditing, setIsEditing] = useState(false);

    // const [mealName, setMealName] = useState(name);
    // const [mealDescription, setMealDescription] = useState(description);
    // const [mealPrepTime, setMealPrepTime] = useState(prep_time);
    // const [mealCookingTime, setMealCookingTime] = useState(cooking_time);
    // const [mealServing, setMealServing] = useState(serving);
    // const [mealPreparation, setMealPreparation] = useState(preparation);
    // const [mealCookingInstructions, setMealCookingInstructions] = useState(cooking_instructions);
    // const [mealCategory, setMealCategory] = useState(category);
    // const [mealIngredients, setMealIngredients] = useState(mealAttributes?.meal_ingredients.data);
    // const [ingredientsToRemove, setIngredientsToRemove] = useState([]);
    // const [ingredientSearch, setIngredientSearch] = useState('');
    // const [filteredIngredients, setFilteredIngredients] = useState([]);
    // const [selectedIngredient, setSelectedIngredient] = useState(null);
    // const [newIngredientAmount, setNewIngredientAmount] = useState('');
    // const [newIngredientUnit, setNewIngredientUnit] = useState('g');
    const variants = mealData?.attributes?.meal_instances?.data;

    const calculateNutrition = () => {
        let totalProtein = 0;
        let totalCarbs = 0;
        let totalFat = 0;
        let totalKcals = 0;
    
        mealIngredients.forEach(ingredient => {
            const amount = parseFloat(ingredient.attributes.amount);
            const macros = ingredient.attributes.macros
                ? ingredient.attributes.macros[0]
                : ingredient.attributes.ingredients.data[0].attributes.macros[0];
            const unit = ingredient.attributes.unit;
    
            if (unit === "stk") {
                totalProtein += macros.protein * amount;
                totalCarbs += macros.carbs * amount;
                totalFat += macros.fat * amount;
                totalKcals += macros.kcals * amount;
            } else if (unit === "g") {
                totalProtein += (macros.protein / 100) * amount;
                totalCarbs += (macros.carbs / 100) * amount;
                totalFat += (macros.fat / 100) * amount;
                totalKcals += (macros.kcals / 100) * amount;
            } else if (unit === "dl") {
                const gramsEquivalent = amount * 100;
                totalProtein += (macros.protein / 100) * gramsEquivalent;
                totalCarbs += (macros.carbs / 100) * gramsEquivalent;
                totalFat += (macros.fat / 100) * gramsEquivalent;
                totalKcals += (macros.kcals / 100) * gramsEquivalent;
            }
        });

        const perServing = Math.max(1, mealServing);
    
        return {
            totalKcals: Math.round(totalKcals / perServing),
            totalProtein: Math.round(totalProtein / perServing),
            totalCarbs: Math.round(totalCarbs / perServing),
            totalFat: Math.round(totalFat / perServing)
        };
    };
    
    const nutritionValues = calculateNutrition();

    const handleEditToggle = () => {
        if (isEditing) {
            handleEditMeal();
        } else {
            setIsEditing(true);
        }
    };

    const handleDeleteMeal = async () => {
        const confirmed = window.confirm("Er du sikker på at du vil slette dette måltidet?");
        if (!confirmed) return;

        setIsLoading(true);
        try {
            await axiosAuthFetch(`${API_BASE_URL}/meals/${mealData.id}`, {}, 'DELETE');
            alert("Måltid slettet!");
            window.location.reload(); // Gå tilbake til listen etter sletting
        } catch (error) {
            console.error("Error deleting meal:", error);
            alert("Kunne ikke slette måltidet. Prøv igjen senere.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleIngredientSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setIngredientSearch(searchValue);
        setFilteredIngredients(
            searchValue
                ? allIngredients.filter((ingredient) =>
                    ingredient.attributes.name.toLowerCase().includes(searchValue)
                  )
                : []
        );
    };

    const handleAddIngredient = () => {
        if (!selectedIngredient || !newIngredientAmount) return;
        console.log(selectedIngredient);
        // const newIngredient = {
        //     ...selectedIngredient,
        //     attributes: {
        //         ...selectedIngredient.attributes,
        //         amount: newIngredientAmount,
        //         unit: newIngredientUnit,
        //     },
        // };

        const newIngredient = {
            attributes: {
                amount: newIngredientAmount,
                unit: newIngredientUnit,
                ingredients: {
                    data: [
                        {
                            id: selectedIngredient.id,
                            attributes: selectedIngredient.attributes
                        }
                    ]
                }
            }
        };

        setMealIngredients((prevIngredients) => [...prevIngredients, newIngredient]);
        setIngredientSearch('');
        setSelectedIngredient(null);
        setNewIngredientAmount('');
        setNewIngredientUnit('g');
    };

    const handleIngredientChange = (index, field, value) => {
        const updatedIngredients = [...mealIngredients];
        updatedIngredients[index].attributes[field] = value;
        setMealIngredients(updatedIngredients);
    };

    const handleEditMeal = async () => {
        try {
          setIsLoading(true);
      
          // Oppdater måltidsinformasjon
          await axiosAuthFetch(`${API_BASE_URL}/meals/${mealData.id}`, {
            data: {
              name: mealName,
              description: mealDescription,
              prep_time: mealPrepTime,
              cooking_time: mealCookingTime,
              preparation: mealPreparation,
              cooking_instructions: mealCookingInstructions,
              serving: mealServing,
              category: mealCategory
            }
          }, 'PUT');
      
          // Oppdater eksisterende ingredienser
          const existingIngredients = mealIngredients.filter(ingredient => ingredient.id);
          const newIngredients = mealIngredients.filter(ingredient => !ingredient.id);
      
          await Promise.all(existingIngredients.map(async (ingredient) => {
            const { amount, unit } = ingredient.attributes;
            await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients/${ingredient.id}`, {
              data: { amount, unit }
            }, 'PUT');
          }));
      
          // Legg til nye ingredienser
          await Promise.all(newIngredients.map(async (ingredient) => {
            const { amount, unit } = ingredient.attributes;
            await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients`, {
              data: {
                meals: [mealData.id],
                ingredients: [ingredient.attributes.ingredients.data[0].id],
                amount,
                unit
              }
            }, 'POST');
          }));
      
          // Fjern ingredienser som er merket for sletting
          await Promise.all(ingredientsToRemove.map(async (id) => {
            await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients/${id}`, {}, 'DELETE');
          }));
      
          const nutritionValues = calculateNutrition();

          // 6. Oppdater måltidet med de beregnede macros
          await axiosAuthFetch(`${API_BASE_URL}/meals/${mealData.id}`, {
            data: {
              proteins: nutritionValues.totalProtein,
              carbs: nutritionValues.totalCarbs,
              fats: nutritionValues.totalFat,
              kcals: nutritionValues.totalKcals
            }
          }, 'PUT');

          alert("Måltid og ingredienser oppdatert!");
          setIsLoading(false);
          setIsEditing(false);
          setIngredientsToRemove([]);
        } catch (error) {
          console.error("Error updating meal or ingredients:", error);
          alert("Det oppstod en feil ved oppdatering av måltidet eller ingrediensene.");
          setIsLoading(false);
        }
      };

    const getIngredientAttribute = (ingredient, attribute) => {
        // Hvis ingrediensen kommer fra mealData
        if (ingredient.attributes.ingredients) {
            return ingredient.attributes.ingredients.data[0].attributes[attribute];
        }
        // Hvis ingrediensen kommer fra allIngredients (props)
        return ingredient.attributes[attribute];
    };

    const handleRemoveIngredient = (index) => {
        const ingredientToRemove = mealIngredients[index];
        if (ingredientToRemove.id) {
          setIngredientsToRemove([...ingredientsToRemove, ingredientToRemove.id]);
        }
        setMealIngredients(mealIngredients.filter((_, i) => i !== index));
      };

      console.log(mealData);
    
    if(isLoading) {
        return <Loader />;
    }

    if (!mealData) {
      return <Loader />;
    }

    return (
        <div className={`${styles.detailContainer} appearing-container`}>

            {/* === DESKTOP-VISNING === */}
      {isDesktop && (
        <>
          {/* "Header" øverst */}
          <div className={styles.desktopHeader}>
            <div className={styles.headerLeft}>
              <BackButton />
            </div>
            <div className={styles.headerCenter}>
              {isEditing ? (
                <input
                  value={mealName}
                  onChange={(e) => setMealName(e.target.value)}
                  className={styles.inputName}
                />
              ) : (
                <h2>{mealName}</h2>
              )}
              <div className={styles.category}>
                {isEditing ? (
                  <select
                    value={mealCategory}
                    onChange={(e) => setMealCategory(e.target.value)}
                    className={styles.inputName}
                  >
                    <option value="Frokost">Frokost</option>
                    <option value="Lunsj">Lunsj</option>
                    <option value="Middag">Middag</option>
                    <option value="Kveldsmat">Kveldsmat</option>
                    <option value="Snack">Snack</option>
                    <option value="Mellommåltid">Mellommåltid</option>
                  </select>
                ) : (
                  <p>{mealCategory}</p>
                )}
              </div>
            </div>
          </div>

          {/* To-kolonners layout med uavhengig scrolling */}
          <div className={styles.desktopContent}>
            {/* Venstre kolonne */}
            <div className={styles.leftColumn}>
              <div className={styles.mealImage} style={{ backgroundImage: `url(${imageUrl})` }} />

              <div className={`${styles.prepInfo}`}>
                {/* For enkelhet, map over prep_time / cooking_time / serving */}
                {["prep_time", "cooking_time", "serving"].map((field, idx) => (
                  <div className={styles.prepChild} key={idx}>
                    <Image
                      src={
                        field === "prep_time"
                          ? Clock
                          : field === "cooking_time"
                            ? Chef
                            : Serving
                      }
                      fluid
                      alt={`${field} icon`}
                      className={styles.iconContainer}
                    />
                    {isEditing ? (
                      <input
                        type="number"
                        value={
                          field === "prep_time"
                            ? mealPrepTime
                            : field === "cooking_time"
                              ? mealCookingTime
                              : mealServing
                        }
                        onChange={(e) => {
                          if (field === "prep_time") setMealPrepTime(e.target.value);
                          else if (field === "cooking_time") setMealCookingTime(e.target.value);
                          else setMealServing(e.target.value);
                        }}
                        className={styles.editInputIcons}
                      />
                    ) : (
                      <p>
                        {
                          field === "prep_time"
                            ? mealPrepTime
                            : field === "cooking_time"
                              ? mealCookingTime
                              : mealServing
                        } {field === "serving" ? "porsjoner" : "min"}
                      </p>
                    )}
                  </div>
                ))}
              </div>

              {/* Næringsinnhold */}
              <div className={styles.nutritionContainer}>
                <h3>Næringsinnhold</h3>
                <div className={styles.nutriSubHeader}>Per måltid</div>
                <div className={styles.nutriChild}>
                  <div className={styles.nutriSub1}>
                    <div className={styles.nutriP}>{nutritionValues.totalKcals} kcal</div>
                    <div className={styles.nutriS}>Kalorier</div>
                  </div>
                  <div className={styles.nutriSub1}>
                    <div className={styles.nutriP}>{nutritionValues.totalProtein} g</div>
                    <div className={styles.nutriS}>Protein</div>
                  </div>
                  <div className={styles.nutriSub1}>
                    <div className={styles.nutriP}>{nutritionValues.totalCarbs} g</div>
                    <div className={styles.nutriS}>Karbohydrater</div>
                  </div>
                  <div className={styles.nutriSub1}>
                    <div className={styles.nutriP}>{nutritionValues.totalFat} g</div>
                    <div className={styles.nutriS}>Fett</div>
                  </div>
                </div>
              </div>

              {/* Ingredienser */}
              <div className={styles.ingredientsContainer}>
                <h3>Ingredienser</h3>
                {mealIngredients.map((ingredient, index) => (
                  <div className={styles.ingredientChild} key={ingredient.id || index}>
                    <div className={styles.ingredientIconContainer}>
                      <Image src={IngredientIcon} fluid alt="ingredient icon" />
                    </div>
                    <div>{getIngredientAttribute(ingredient, "name")}</div>

                    <div className={styles.ingredientSubContainer}>
                      {isEditing ? (
                        <div className={styles.inputSubContainer}>
                          <input
                            type="number"
                            value={ingredient.attributes.amount}
                            onChange={(e) => handleIngredientChange(index, 'amount', e.target.value)}
                            className={styles.inputEditAmounts}
                          />
                          <div className={styles.ingredientText}>
                            {ingredient.attributes.unit}
                          </div>
                          <button
                            onClick={() => handleRemoveIngredient(index)}
                            className={styles.removeIngredientButton}
                          >
                            Fjern
                          </button>
                        </div>
                      ) : (
                        <div className={styles.ingredientText}>
                          {ingredient.attributes.amount} {ingredient.attributes.unit}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {isEditing && (
                <div className={styles.addIngredientContainer}>
                    <h3>Legg til ingrediens</h3>
                    {!selectedIngredient ? ( // Hvis ingen ingrediens er valgt
                    <>
                        <input
                        type="text"
                        value={ingredientSearch}
                        onChange={handleIngredientSearch}
                        placeholder="Søk etter ingrediens"
                        className={styles.ingredientSearchInput}
                        />
                        <div className={styles.filteredIngredients}>
                        {filteredIngredients.map((ingredient) => (
                            <div
                            key={ingredient.id}
                            className={`${styles.ingredientOption} ${
                                selectedIngredient && selectedIngredient.id === ingredient.id
                                ? styles.selected
                                : ''
                            }`}
                            onClick={() => setSelectedIngredient(ingredient)} // Velg ingrediens
                            >
                            {ingredient.attributes.name}
                            </div>
                        ))}
                        </div>
                    </>
                    ) : (
                    // Når en ingrediens er valgt, vis kun inputfeltet for mengde og legg-til-knappen
                    <div className={styles.addIngredientDetails}>
                        <h4>Valgt ingrediens: {selectedIngredient.attributes.name}</h4>
                        <div className={styles.row}>
                        <input
                            type="number"
                            value={newIngredientAmount}
                            onChange={(e) => setNewIngredientAmount(e.target.value)}
                            placeholder="Mengde"
                            className={styles.ingredientAmountInput}
                        />
                        {newIngredientUnit}
                        </div>
                        <button
                        onClick={() => {
                            handleAddIngredient();
                            setSelectedIngredient(null); // Tilbakestill valget
                            setIngredientSearch(''); // Tilbakestill søkefeltet
                        }}
                        className={styles.addIngredientButton}
                        >
                        Legg til ingrediens
                        </button>
                        <button
                        onClick={() => setSelectedIngredient(null)} // Tilbakestill hvis brukeren ombestemmer seg
                        className={styles.cancelButton}
                        >
                        Avbryt
                        </button>
                    </div>
                    )}
                </div>
                )}

              {/* Tabs (beskrivelse, prep, instruksjoner) */}
              <div className={styles.tabsContainer}>
                <div className={styles.customTabs}>
                  {["preparation", "description", "cooking_instructions"].map((tab, idx) => (
                    <button
                      key={idx}
                      className={`${styles.tabButton} ${
                        activeTab === tab ? styles.activeTab : ""
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab === "preparation"
                        ? "Prep"
                        : tab === "description"
                          ? "Beskrivelse"
                          : "Instruksjoner"}
                    </button>
                  ))}
                </div>

                <div className={styles.tabContent}>
                  {isEditing ? (
                    <textarea
                      value={
                        activeTab === "preparation"
                          ? mealPreparation
                          : activeTab === "description"
                            ? mealDescription
                            : mealCookingInstructions
                      }
                      onChange={(e) => {
                        if (activeTab === "preparation") setMealPreparation(e.target.value);
                        else if (activeTab === "description") setMealDescription(e.target.value);
                        else setMealCookingInstructions(e.target.value);
                      }}
                      className={styles.inputTextArea}
                    />
                  ) : (
                    <p>
                      {activeTab === "preparation"
                        ? mealPreparation
                        : activeTab === "description"
                          ? mealDescription
                          : mealCookingInstructions || "Ingen info tilgjengelig"}
                    </p>
                  )}
                </div>
              </div>

              {/* Knapper (rediger / slett / lagre / avbryt) */}
              <div className={`${styles.buttonContainer} ${isEditing ? styles.editMode : ''}`}>
                {isEditing ? (
                  <>
                    <button onClick={handleEditToggle} className={styles.saveButton}>
                      Lagre
                    </button>
                    <button onClick={() => setIsEditing(false)} className={styles.cancelButton}>
                      Avbryt
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={() => setIsEditing(true)} className={styles.editButton}>
                      Rediger
                    </button>
                    <button onClick={handleDeleteMeal} className={styles.deleteButton}>
                      Slett
                    </button>
                  </>
                )}
              </div>
            </div>

            {/* Høyre kolonne */}
            <div className={styles.rightColumn}>
              <div className={styles.nutritionContainer}>
                <ClientsList allIngredients={allIngredients} mealId={mealData.id} />
              </div>
              <div className={styles.nutritionContainer}>
                <VariantsList
                  mealData={mealData}
                  allIngredients={allIngredients}
                  variants={variants}
                  clients={clients}
                  mealId={mealData.id}
                />
              </div>
            </div>
          </div>
        </>
      )}
            {!isDesktop && (
                <>
                <div className={styles.headerLeft}>
                  {/* <BackButton /> */}
                </div>
                <div className={styles.name}>
                    {isEditing ? (
                        <input
                            value={mealName}
                            onChange={(e) => setMealName(e.target.value)}
                            className={styles.inputName}
                        />
                    ) : (
                        <h2>{mealName}</h2>
                    )}
                </div>
                <div className={styles.category}>
                    {isEditing ? (
                        <select value={mealCategory} onChange={(e) => setMealCategory(e.target.value)} className={styles.inputName}>
                            <option value="Frokost">Frokost</option>
                            <option value="Lunsj">Lunsj</option>
                            <option value="Middag">Middag</option>
                            <option value="Kveldsmat">Kveldsmat</option>
                            <option value="Snack">Snack</option>
                            <option value="Mellommåltid">Mellommåltid</option>
                        </select>
                    ) : (
                        <p>{mealCategory}</p>
                    )}
                </div>
                <div className={styles.mealImage} style={{ backgroundImage: `url(${imageUrl})` }} />
                <div className={styles.prepInfo}>
                    {["prep_time", "cooking_time", "serving"].map((field, idx) => (
                        <div className={styles.prepChild} key={idx}>
                            <Image src={field === "prep_time" ? Clock : field === "cooking_time" ? Chef : Serving} fluid alt={`${field} icon`} className={styles.iconContainer} />
                            {isEditing ? (
                                <input
                                    type="number"
                                    value={field === "prep_time" ? mealPrepTime : field === "cooking_time" ? mealCookingTime : mealServing}
                                    onChange={(e) => field === "prep_time" ? setMealPrepTime(e.target.value) : field === "cooking_time" ? setMealCookingTime(e.target.value) : setMealServing(e.target.value)}
                                    className={styles.editInputIcons}
                                />
                            ) : (
                                <p>{field === "prep_time" ? mealPrepTime : field === "cooking_time" ? mealCookingTime : mealServing} {field === "serving" ? "porsjoner" : "min"}</p>
                            )}
                        </div>
                    ))}
                </div>
                <div className={styles.nutritionContainer}>
                    <h3>Næringsinnhold</h3>
                    <div className={styles.nutriSubHeader}>Per måltid</div>
                    <div className={styles.nutriChild}>
                        <div className={styles.nutriSub1}>
                            <div className={styles.nutriP}>{nutritionValues.totalKcals} kcal</div>
                            <div className={styles.nutriS}>Kalorier</div>
                        </div>
                        <div className={styles.nutriSub1}>
                            <div className={styles.nutriP}>{nutritionValues.totalProtein}g</div>
                            <div className={styles.nutriS}>Protein</div>
                        </div>
                        <div className={styles.nutriSub1}>
                            <div className={styles.nutriP}>{nutritionValues.totalCarbs}g</div>
                            <div className={styles.nutriS}>Karbohydrater</div>
                        </div>
                        <div className={styles.nutriSub1}>
                            <div className={styles.nutriP}>{nutritionValues.totalFat}g</div>
                            <div className={styles.nutriS}>Fett</div>
                        </div>
                    </div>
                </div>
                <div className={styles.ingredientsContainer}>
                <h3>Ingredienser</h3>
                    {mealIngredients.map((ingredient, index) => (
                        <div className={styles.ingredientChild} key={ingredient.id || index}>
                            <div className={styles.ingredientIconContainer}>
                                <Image src={IngredientIcon} fluid alt="ingredient icon" />
                            </div>
                            <div>{getIngredientAttribute(ingredient, "name")}</div>
                            <div className={styles.ingredientSubContainer}>
                                {isEditing ? (
                                    <div className={styles.inputSubContainer}>
                                        <input
                                            type="number"
                                            value={ingredient.attributes.amount}
                                            onChange={(e) => handleIngredientChange(index, 'amount', e.target.value)}
                                            className={styles.inputEditAmounts}
                                        />
                                        <div className={styles.ingredientText}>{ingredient.attributes.unit}</div>
                                        <button
                                            onClick={() => handleRemoveIngredient(index)}
                                            className={styles.removeIngredientButton}
                                        >
                                            Fjern
                                        </button>
                                    </div>
                                ) : (
                                    <div className={styles.ingredientText}>
                                        {ingredient.attributes.amount} {ingredient.attributes.unit}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                    {isEditing && (
                <div className={styles.addIngredientContainer}>
                    <h3>Legg til ingrediens</h3>
                    {!selectedIngredient ? ( // Hvis ingen ingrediens er valgt
                    <>
                        <input
                        type="text"
                        value={ingredientSearch}
                        onChange={handleIngredientSearch}
                        placeholder="Søk etter ingrediens"
                        className={styles.ingredientSearchInput}
                        />
                        <div className={styles.filteredIngredients}>
                        {filteredIngredients.map((ingredient) => (
                            <div
                            key={ingredient.id}
                            className={`${styles.ingredientOption} ${
                                selectedIngredient && selectedIngredient.id === ingredient.id
                                ? styles.selected
                                : ''
                            }`}
                            onClick={() => setSelectedIngredient(ingredient)} // Velg ingrediens
                            >
                            {ingredient.attributes.name}
                            </div>
                        ))}
                        </div>
                    </>
                    ) : (
                    // Når en ingrediens er valgt, vis kun inputfeltet for mengde og legg-til-knappen
                    <div className={styles.addIngredientDetails}>
                        <h4>Valgt ingrediens: {selectedIngredient.attributes.name}</h4>
                        <div className={styles.row}>
                        <input
                            type="number"
                            value={newIngredientAmount}
                            onChange={(e) => setNewIngredientAmount(e.target.value)}
                            placeholder="Mengde"
                            className={styles.ingredientAmountInput}
                        />
                        {newIngredientUnit}
                        </div>
                        <button
                        onClick={() => {
                            handleAddIngredient();
                            setSelectedIngredient(null); // Tilbakestill valget
                            setIngredientSearch(''); // Tilbakestill søkefeltet
                        }}
                        className={styles.addIngredientButton}
                        >
                        Legg til ingrediens
                        </button>
                        <button
                        onClick={() => setSelectedIngredient(null)} // Tilbakestill hvis brukeren ombestemmer seg
                        className={styles.cancelButton}
                        >
                        Avbryt
                        </button>
                    </div>
                    )}
                </div>
                )}
                <div className={styles.tabsContainer}>
                    <div className={styles.customTabs}>
                        {["preparation", "description", "cooking_instructions"].map((tab, idx) => (
                            <button key={idx} className={`${styles.tabButton} ${activeTab === tab ? styles.activeTab : ""}`} onClick={() => setActiveTab(tab)}>
                                {tab === "preparation" ? "Prep" : tab === "description" ? "Beskrivelse" : "Instruksjoner"}
                            </button>
                        ))}
                    </div>

                    <div className={styles.tabContent}>
                        {isEditing ? (
                            <textarea
                                value={activeTab === "preparation" ? mealPreparation : activeTab === "description" ? mealDescription : mealCookingInstructions}
                                onChange={(e) => activeTab === "preparation" ? setMealPreparation(e.target.value) : activeTab === "description" ? setMealDescription(e.target.value) : setMealCookingInstructions(e.target.value)}
                                className={styles.inputTextArea}
                            />
                        ) : (
                            <p>{activeTab === "preparation" ? mealPreparation : activeTab === "description" ? mealDescription : mealCookingInstructions || "Ingen info tilgjengelig"}</p>
                        )}
                    </div>
                </div>
                <div className={`${styles.buttonContainer} ${isEditing ? styles.editMode : ''}`}>
                    {isEditing ? (
                        <>
                            <button onClick={handleEditToggle} className={styles.saveButton}>
                                Lagre
                            </button>
                            <button onClick={() => setIsEditing(false)} className={styles.cancelButton}>
                                Avbryt
                            </button>
                        </>
                    ) : (
                        <>
                        <button onClick={() => setIsEditing(true)} className={styles.editButton}>
                            Rediger
                        </button>
                        <button onClick={handleDeleteMeal} className={styles.deleteButton}>Slett</button>
                        </>
                    )}
                </div>
                <div className={styles.nutritionContainer}>
                    <ClientsList allIngredients={allIngredients} mealId={mealData?.id} />
                </div>
                <div className={styles.nutritionContainer}>
                    <VariantsList mealData={mealData} allIngredients={allIngredients} variants={variants} clients={clients} mealId={mealData?.id} />
                </div>
                </>
            )}
        </div>
    );
}